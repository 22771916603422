import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import {  Observable, throwError } from 'rxjs';
 // import { Observable, Subject, asapScheduler, pipe, of, from, interval, merge, fromEvent } from 'rxjs';

import { catchError } from 'rxjs/operators'; 
import { Router } from '@angular/router';


@Injectable()
export class httpInterceptor implements HttpInterceptor {

  constructor(private router: Router){}
token:any;
 userlog :  any= [];
 retrievedData : any = [] ;

 intercept(request: HttpRequest<any>, newRequest: HttpHandler): Observable<HttpEvent<any>> {

 // add authorization header to request
 //Get Token data from local storage
 this.token=localStorage.getItem('token');
 const tokenInfo = JSON.parse(this.token);

 if (tokenInfo && tokenInfo.token) {
        request = request.clone({

   setHeaders: {
      Authorization: `Bearer ${tokenInfo.token}`
   }

  });
 }
 this.retrievedData =  sessionStorage.getItem("userlogs") ;
//  var x = JSON.parse(this.retrievedData)
 if(request.method != "GET") {
  this.userlog.push(" Methode type : " + request.method + " Url : " + request.url + "Date :"+ new Date())  ;
   }
   sessionStorage.setItem("userlogs", JSON.stringify( this.userlog));
   return newRequest.handle(request)
   .pipe(catchError((err => {
       // onError
       console.log(err);
       if (err instanceof HttpErrorResponse) {
           console.log(err.status);
           console.log(err.statusText);
           if (err.status === 401) {
            this.router.navigate(["/"]);
             
        
           }
       }
       return throwError(() => err);
   }))) as any
}
decodeToken(token: string): any {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const decodedData = JSON.parse(window.atob(base64));
  return decodedData;
}
}

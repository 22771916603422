<div class="verification-container error" >
    <div class="logo">
        <img  src="../../../assets/logo-n.png"></div>
    <div class="verification-message">
        <div class="error-animation">
            <i class="fas fa-times-circle fa-5x" style="color: #d10101; margin: 31px 0px"></i>
        </div>
        <h2 style="color: white;">Token invalide ou expiré</h2>

    </div>
</div>


<!-- <app-sidebar></app-sidebar> -->
	<!-- Google font -->
	<link href="https://fonts.googleapis.com/css?family=Maven+Pro:400,900" rel="stylesheet">





	<div id="notfound">
		<div class="notfound">
			<div class="notfound-404">
				<h1>404</h1>
			</div>
			<h2>Nous sommes désol&eacute;s, Page non trouvée !</h2>
<p>La page que vous recherchez a peut-&ecirc;tre &eacute;t&eacute; supprim&eacute;e, son nom a &eacute;t&eacute; modifi&eacute;, ou elle est temporairement inaccessible.</p>
<a (click)="backtohome()">Retour à la page d'accueil</a>

		</div>
	</div>




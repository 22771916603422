import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client.service';
import { VilleService } from 'src/app/services/ville.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-informations-personnelles',
  templateUrl: './informations-personnelles.component.html',
  styleUrls: ['./informations-personnelles.component.css']
})
export class InformationsPersonnellesComponent implements OnInit {
  client: any;
  selectedville: any;
  commandepaid: boolean = false;
  clientform: FormGroup = new FormGroup({});
  emptyfield: boolean = false;
  cne: string = '';
  Limitcities: number = 10;
  searchTerm: string = '';
  bacetranger:boolean = false;
  submitted = false;

  // BehaviorSubject to hold the list of cities
   listville = new BehaviorSubject<{ ville: any }[]>([]);
  listville$: Observable<{ ville: string }[]> = this.listville.asObservable();

  filteredCities$: Observable<{ ville: string }[]>;

  constructor(
    private villeservice: VilleService,
    private clientserv: ClientService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) {
    this.clientform = this.formBuilder.group({
      cin: ['', [Validators.required, Validators.minLength(4)]],
      cne: ['', [Validators.minLength(10), Validators.pattern('^[a-zA-Z0-9]{10}$')]],
      nom: ['', [Validators.required, Validators.pattern('^[a-zA-Z\\s\\-]+$')]],
      prenom: ['', [Validators.required, Validators.pattern('^[a-zA-Z\\s\\-]+$')]],
      telephone: ['', [Validators.required, Validators.pattern('^0\\d{9}$')]],
      adresse: ['', [Validators.required]],
      ville: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
    const payee = localStorage.getItem("payee");
    this.commandepaid = payee === "true";

    this.atthebeginning();
    this.fetchCities();

    // Setup the filteredCities observable
    this.filteredCities$ = this.clientform.get('ville')!.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || ''))
    );
  }
  restrictNonNumeric(event: KeyboardEvent): void {
    const charCode = event.charCode;
    // Allow only numbers (ASCII codes 48-57 for digits 0-9)
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }
  onchangebacetranger(event:Event){
    this.bacetranger = !this.bacetranger
   
  }
  private _filter(value: string): { ville: string }[] {
    const filterValue = value.toLowerCase();
    const currentList = this.listville.value;
    return currentList.filter(city => city.ville.toLowerCase().includes(filterValue));
  }

  cneChange(event: Event) {
    const cneInput = event.target as HTMLInputElement;
    const cne = cneInput.value;
    this.cne = cne;
    this.emptyfield = cne.length === 0;
  }

  atthebeginning() {
    const client = localStorage.getItem("user");
    if (client) {
      this.client = JSON.parse(client);
    }

    this.clientserv.getclientbyid(this.client.id).subscribe(
      response => {
        this.client = response;
        this.clientform.patchValue({
          nom: this.client.nom,
          prenom: this.client.prenom,
          email: this.client.email,
          cin: this.client.cin,
          cne: this.client.cne,
          telephone: this.client.telephone,
          adresse: this.client.adresse,
          ville: this.client.ville,
        });
        this.selectedville = this.client.ville;
        if(this.client.cne === null || this.client.cne ===''){
          this.bacetranger = true;
        }
        this.fetchCities();

      },
      error => {
        console.error('Erreur lors de la récupération des informations du client:', error);
        if (error && error.error && error.error.message) {
          console.error('Message d\'erreur du serveur:', error.error.message);
        }
      }
    );
  }

  onFormSubmit(): void {
    if (this.bacetranger) {
      delete this.clientform.value.cne;
    }
    delete this.clientform.value.email;

    if (this.clientform.valid) {
      this.clientform.value.nomville = this.clientform.value.ville;

      this.clientserv.updateclient(this.clientform.value, this.client.id).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error: any) => {
          if (error.error.message === "Une autre demande de changement Est deja En cours") {
            this.toastr.info('Une autre demande de changement est déjà en cours de traitement.');
          } else {
            this.toastr.warning(error.error.message);
          }
        }
      );
    }
  }

  onFormSubmit2(updatedClient: any): void {
    delete updatedClient.email;
   delete this.clientform.value.email
    if (this.clientform.valid) {
      updatedClient.nomville = updatedClient.ville;
      if (this.bacetranger) {
        delete updatedClient.cne;
      }
      this.clientserv.newdemande(updatedClient, this.client.id).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        }, 
        (error: any) => {
          if (error.error.message === "Une autre demande de changement Est deja En cours") {
            this.toastr.info('Une autre demande de changement est déjà en cours de traitement.');
          } else {
            this.toastr.warning(error.error.message);
          }
        }
      );
    } else {
      this.clientform.markAllAsTouched();
    }
  }

  fetchCities() {
    this.villeservice.Getlistvilleforstudent(this.Limitcities).subscribe(
      (data: { ville: string }[]) => {
        this.listville.next(data);
       
        const IndexVille = this.listville.value.findIndex(x=>x.ville === this.client.ville);
        // this.selectedville = this.listville[IndexVille]
        console.log(IndexVille);
        if(IndexVille === -1){
          this.villeservice.GetVillebyname(this.client.ville).subscribe({
            next:(value:any)=> {
              this.listville.value.push({ ville: value.ville });
              this.clientform.patchValue({ ville: value.ville });
            },
          })
        }
      },
      (error) => {
        console.error('Erreur lors de la récupération des villes:', error);
      }
    ); 
  }
  searchCities(){
    if(this.searchTerm == null || this.searchTerm ===""){
      this.fetchCities();
      return
    }
   const data={
      term:this.searchTerm,
      limit:this.Limitcities
    }
    this.villeservice.searchVilleForstudent(data).subscribe((data: { ville: string }[]) => {
      const selectedCityExists = data.find(city => city.ville === this.selectedville.ville);
      if (!selectedCityExists && this.selectedville) {
        data.push({ ville: this.selectedville });
    }
      this.listville.next(data);


      
      if (!data.length) {
        this.clientform.value.ville.patchValue({ ville: this.selectedville.ville });
      }
     
    },
    (error) => {
      console.error('Erreur lors de la récupération des villes:', error);
    })
  }

  onSubmit() {
    this.submitted = true;

  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-cmi-success',
  templateUrl: './cmi-success.component.html',
  styleUrls: ['./cmi-success.component.css']
})
export class CmiSuccessComponent {

}

import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { ClientService } from '../services/client.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VilleService } from '../services/ville.service';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';


// import { LoginService } from '../services/login.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit{
  public form!: FormGroup;
  public form2: FormGroup;
  // public myGroup!:FormGroup
  isSubmitted  =  false;
  changepass:boolean =true;
  checkboxdisabled = true;
  error: any;
  client:any;
  isapprouved:boolean= false;
  bacetranger:boolean = false;
  submitted = false;
  submitted2 = false;
  showValidationPopup: boolean = false;
   validationcheck: boolean=false;
   Limitcities: number = 10;
   searchTerm: string = '';
   selectedTab: string = 'signIn'; 
     showSignUp: boolean = false;


   // BehaviorSubject to hold the list of cities
    listville = new BehaviorSubject<{ ville: any }[]>([]);
   listville$: Observable<{ ville: string }[]> = this.listville.asObservable();
 
   filteredCities$: Observable<{ ville: string }[]>;

//otp
   email: string = '';
   otpCode: string = '';
  // captcha
      siteKey='6Ldx-CQoAAAAAM2tAO1i5jVXKJMB695t4wHmOI2Z';
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'fr';
  
  constructor(
    private villeservice:VilleService,
    private loginservice: ClientService,
   private router: Router,
   private formBuilder: FormBuilder,
   private toastr:ToastrService,
   private clientser:ClientService,
   private dialog : MatDialog
  //  private Toastr:ToastrService,
  //  private permissionsService: NgxPermissionsService,
 ) {
  this.client={
    cin:'',
    cne:'',
    nom:'',
    prenom:'',
    email:'',
    ville:'',password:''
  }

      // Initialisation du formulaire Login
     
  this.form =this.formBuilder.group({
    email: ['', [Validators.required,Validators.email]],
    password: ['', Validators.required],
     recaptcha: ['', Validators.required]
  });
      // Initialisation du formulaire Register

  this.form2 =this.formBuilder.group({
    cin: ['', [Validators.required, Validators.minLength(4)]],
    cne: ['', [Validators.minLength(10), Validators.pattern('^[a-zA-Z0-9]{10}$')]],
    nom: ['', [Validators.required, Validators.pattern('^[a-zA-Z\\s\\-]+$')]],
    prenom: ['', [Validators.required, Validators.pattern('^[a-zA-Z\\s\\-]+$')]], 
    telephone: ['', [Validators.required,Validators.pattern('^0\\d{9}$')]],
    adresse: ['', [Validators.required]],
    ville: ['', [Validators.required]],
    email: ['', [Validators.email, Validators.required]],
    password: ['', [Validators.required,Validators.maxLength(12), Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d).{6,}$/)]],
    confirmpassword: ['', [Validators.required]],
    confirmationemail: ['', [Validators.email, Validators.required]]
  }, { validators: [this.passwordMatchValidator(), this.emailMatchValidator()] });
  }
  handleReset(){

  }
 

  handleExpire(){
    
  }
  handleLoad(){
    
  }
  handleSuccess(event:any){
    console.log(event)
  }
  restrictNonNumeric(event: KeyboardEvent): void {
    const charCode = event.charCode;
    // Allow only numbers (ASCII codes 48-57 for digits 0-9)
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  ngOnInit(){
  //   this.myGroup = new FormGroup({
  //     firstName: new FormControl()
  // });
    this.fetchCities();
   
    const signUpButton = document.getElementById('signUp') as HTMLElement;
  const signInButton = document.getElementById('signIn') as HTMLElement;
  const container = document.getElementById('container') as HTMLElement;
  if(signUpButton){


  signUpButton.addEventListener('click', (): void => {
      container.classList.add("right-panel-active");
  });
  
  signInButton.addEventListener('click', (): void => {
      container.classList.remove("right-panel-active");
  });
    }
  }
change(){
  this.checkboxdisabled = !this.checkboxdisabled;
}
  // onchangebacetranger(event:Event){
  //   // const checkbox = event.target as HTMLInputElement;
  //   // this.bacetranger = checkbox.checked;
  //   // console.log(this.bacetranger);
  //   this.bacetranger = !this.bacetranger
  // }
  emailMatchValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const email = control.get('email')?.value;
      const confirmationEmail = control.get('confirmationemail')?.value;
  
      return email === confirmationEmail ? null : { emailMismatch: true };
    };
  }
  passwordMatchValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const pass = control.get('password')?.value;
      const confirmpass = control.get('confirmpassword')?.value;
  
      return pass === confirmpass ? null : { passwordMismatch: true };
    };
  }
  // passwordMatchValidator(form: FormGroup): { [key: string]: boolean } | null {
  //   const password = form.controls['password'].value;
  //   const confirmPassword = form.controls['confirmPassword'].value;
 
  //   if (password !== confirmPassword) {
  //     return { 'passwordMismatch': true };
  //   }
 
  //   return null;
  // }
  getPasswordInputType(): string {
    return this.changepass ? 'text' : 'password';
  }
  
  togglePasswordVisibility(): void {
    this.changepass = !this.changepass;
  }
  onchangebacetranger(event:Event){
    // const checkbox = event.target as HTMLInputElement;
    // this.bacetranger = checkbox.checked;
    // console.log(this.bacetranger);
    this.bacetranger = !this.bacetranger
    const checkbox = event.target as HTMLInputElement;
  
  // Update CNE validator based on checkbox state
  if (this.bacetranger) {
    this.form2.get('cne')?.clearValidators(); // Remove validators
  } else {
    this.form2.get('cne')?.setValidators([Validators.required,Validators.pattern('^[a-zA-Z0-9]{10}$')]); // Add validators
  }
  this.form2.get('cne')?.updateValueAndValidity();
    
  }
  passwordvalidator(controle :FormControl){
    if(controle.value&& controle.value.length < 8){
      return {passwordvalidator : true}
    }
    else
    return null;
 
  }

  changepassword(){
    this.changepass = !this.changepass;
  }

 //checkbox pour l'acceptation des conditions generals avant de creer un compte 
  checknoxapprouvechanged(event:Event){
    const checkbox = event.target as HTMLInputElement;
    this.isapprouved = checkbox.checked;

  }
 //fonction pour creer un nouveau compte
  register(register:any){
    console.log(this.form2.get('ville')?.value)
    // console.log(this.bacetranger)
    this.form2.markAllAsTouched();
    //si la form est valide
    if (this.form2.valid) {
      if (this.isapprouved == false) {
        this.toastr.info("Vous devez d'abord approuver", 'Info');
      } else if (this.isapprouved == true) {
        this.showValidationPopup = true;
      }
    }

    
      
  }
  preventPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const pastedText = clipboardData.getData('text/plain');
      // interdiction du (copier coller ) dans la confirmation d'email
    }
  }
  // fonction de la connexion 
  login(event?: Event) {
this.form.markAllAsTouched();
//si la forme est valid
    if(this.form.valid){
      if (event) {
        event.preventDefault();
      }
      //envoyer la forme au server 
      this.loginservice.signIn(this.form.value).subscribe({
        next: (res => {
          this.loginservice.storeToken(res);
          
          const decodedToken = this.decodeToken(res.token);
          const decode = JSON.stringify(res);
          const user = res.user;
          const status = res.statu;
          //si les information sont correct et un utilisateur exist avec ces infos
          if (user && res) {
           
            localStorage.setItem("statu", res.statu.toString());
      
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("token", JSON.stringify(res));
            this.clientser.history(res.user.id).subscribe(
              response  => {
                if (response && Array.isArray(response) && response.length > 0) {
                  // Extract current year
                  const currentYear = new Date().getFullYear();
                  const LastYear = new Date().getFullYear()-1;

                  let payeeFound = false; // Flag to track if payee command is found
                  let payeeLastYearFound = false; // Flag to track if payee command is found

for (let i = 0; i < response.length; i++) {
    const commandDate = new Date(response[i].dateeffe);
    if (commandDate.getFullYear() === currentYear && response[i].payee === true) {
        payeeFound = true; // Set flag to true if payee command is found
        break;
    }
    const commandDate2 = new Date(response[i].dateeffe);
    if (commandDate2.getFullYear() === LastYear && response[i].payee === true) {
      payeeLastYearFound = true; // Set flag to true if payee command is found
        break;
    }
}
if (payeeLastYearFound) {
  localStorage.setItem("payeeLastYear", "true");
} else{
  localStorage.removeItem("payeeLastYear");

}
// After the loop, check if payeeFound is true
if (payeeFound) {
    localStorage.setItem("payee", "true");
    this.router.navigateByUrl('/historique');
} else {
    localStorage.removeItem("payee");
    localStorage.setItem("payee", "false");

    this.router.navigateByUrl('/commander');
}  
                } 
                else{
                  localStorage.removeItem("payee");
                  localStorage.setItem("payee", "false");
                
                  this.router.navigateByUrl('/commander');
                }
              },
            error => {
            console.error('Error fetching historique:', error);
            if (error && error.error && error.error.message) {
            console.error('Server error message:', error.error.message);
            }
            }
            );

          }
        }),
        error: (err => {
          if (err.error.message === "Mot de passe Incorrect") {
            this.toastr.info('E-mail ou mot de passe incorrect','info');
          } else if (err.error.message === "Votre Compte n'est pas activé déjà") {
            this.toastr.warning('Votre Compte n\'est pas activé. Nous avons déjà envoyé un lien de vérification à votre adresse Email');
          } else if(err.error.message === "Votre Compte n'est pas activé"){
            this.toastr.warning('Votre Compte n\'est pas activé. Nous avons envoyé un lien de vérification à votre adresse Email');

          }
           else if (err.status !== 400) {
            console.error('Login failed', err);
          }
          else{
            if(err.error.Message)
            this.toastr.error(err.error.Message)
          if(err.error.message){
            this.toastr.error(err.error.message);
          }
          }
        })
      });
    }
    
    }
    //list des villes pour la formulaire d'inscription
    // fetchcity(){
    //   this.villeservice.fetchalllvilles().subscribe(
    //     response  => {
    //       // this.toaster.success(response.message,'Success')
    //       this.listville = response;
      
    //       // console.log('Email verification status changed:', response);
          
          
    //     },
    //   error => {
    //   console.error('Error fetching ville:', error);
    //   if (error && error.error && error.error.message) {
    //   console.error('Server error message:', error.error.message);
    //   }
    //   }
    //   );
    // }
    //envoyer la form au server side
    submitForm(){
      this.form2.markAllAsTouched();
    }
    submitForm222(){
      this.loginservice.testtest("").subscribe(
        response => {
          console.log('Payment verified successfully:', response);
          // Handle successful response, e.g., navigate to another page or display a message
        },
        error => {
          console.error('Error verifying payment:', error);
          // Handle error response
        }
      );
    }
    //fonction pour decoder le token
    decodeToken(token: string): any {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const decodedData = JSON.parse(window.atob(base64));
      return decodedData;
    }
    // des function pour marquer ces inputs comme touched!
    onchangecin(){
      this.form2.get('cin')?.markAsTouched();
    }
    onchangecne(){
      this.form2.get('cne')?.markAsTouched();
    }
    onchangenom(){
      this.form2.get('nom')?.markAsTouched();
    }
    onchangeprenom(){
      this.form2.get('prenom')?.markAsTouched();
    }
    onchangetelephone(){
      this.form2.get('telephone')?.markAsTouched();
    }
    onchangeemail(){
      this.form2.get('email')?.markAsTouched();
      this.form2.get('confirmationemail')?.markAsTouched();
    }
    onchangeemaillogin(){
      this.form.get('email')?.markAsTouched();
    }
    onchangepass(){
      this.form2.get('password')?.markAsTouched();
      this.form2.get('confirmpassword')?.markAsTouched();

    }
    onchangepass2(){
      this.form.get('password')?.markAsTouched();

    }
    onchangeville(){
      this.form2.get('ville')?.markAsTouched();
    }
    onchangeadresse(){
      this.form2.get('adresse')?.markAsTouched();
    }


    onSubmit() {
      this.submitted = true;
  
    }
    onSubmit2() {
      this.submitted2 = true;
  
    }
    submitValidationForm() {
      if(this.bacetranger === true){
        delete this.form2.value.cne
      }
      this.form2.markAllAsTouched();
      if ( this.validationcheck) {
        this.loginservice.register(this.form2.value).subscribe(
          response => {
            this.toastr.success(response.message, 'Success');
            this.showValidationPopup = false;
            this.form2.reset();
            this.bacetranger = false
            this.form2.markAsUntouched();
            this.submitted2 = false;
            const ele = document.getElementById("Checkbox") as HTMLInputElement;
            ele.checked = false;
          },
          error => {
            if (error && error.error && error.error.message) {
              this.showValidationPopup = false;
              this.toastr.warning(error.error.message, 'Warning:');
            }
          }
        );
      } else {
        this.toastr.warning('Veuillez confirmer les informations', 'Warning');
      }
    }
  
    closeValidationPopup() {
      this.showValidationPopup = false;
    }
    buttonColor(): string {
      return this.validationcheck ? '#29234A' : '#56536a';
    }
    // onchangeCheckboxValidation(){
    //   this.validationcheck = !this.validationcheck;
    //   console.log(this.validationcheck)
    // }
    scrollToTop(): void {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }






    sendOtp() {
      this.loginservice.sendOtp(this.email).subscribe(response => {
        this.toastr.info("OTP envoyé à votre adresse email");

        console.log('OTP envoyé à votre adresse email');
      });
    }
  
    verifyOtp() {
      this.loginservice.verifyOtp(this.email, this.otpCode).subscribe(response => {
        this.toastr.info("OTP verified");
        console.log('OTP verified');
      });
    }
    fetchCities() {
      this.villeservice.Getlistvilleforstudent(this.Limitcities).subscribe(
        (data: { ville: string }[]) => {
          this.listville.next(data);         
        },
        (error) => {
          console.error('Erreur lors de la récupération des villes:', error);
        }
      );
    }
    searchCities(){
      if(this.searchTerm == null || this.searchTerm ===""){
        this.fetchCities();
        return
      }
     const data={
        term:this.searchTerm,
        limit:this.Limitcities
      }
      this.villeservice.searchVilleForstudent(data).subscribe((data: { ville: string }[]) => {
       
        this.listville.next(data);
  
  
        
       
       
      },
      (error) => {
        console.error('Erreur lors de la récupération des villes:', error);
      })
    }

    updateFormVisibility() {
      if (this.selectedTab === 'signUp') {
        setTimeout(() => this.showSignUp = true, 800); // Show sign-up form after delay
      } else {
        setTimeout(() => this.showSignUp = false, 800); // Hide sign-up form after delay
      }
    }
  
    ngOnChanges() {
      this.updateFormVisibility();
    }

    onInputChange(event: any) {
      const input = event.target.value;
      event.target.value = input.replace(/[^0-9]/g, '');
    }
    
}
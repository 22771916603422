import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client.service';
import { CommandeService } from 'src/app/services/commande.service';

@Component({
  selector: 'app-historique',
  templateUrl: './historique.component.html',
  styleUrls: ['./historique.component.css']
})
export class HistoriqueComponent implements OnInit {
  // Commandes de l'historique
  Commande: any;
  // Informations sur le client
  client: any;
  // Indicateur pour vérifier si la liste des commandes est vide
  listvide: boolean = false;

  // Constructeur pour injecter le service ClientService
  constructor(private clientser: ClientService,private CommandeService:CommandeService,private toastr:ToastrService) {
    // Initialiser les informations du client avec des valeurs par défaut
    this.client = {
      id: '',
      nom: '',
      prenom: '',
      villeEtab: '',
      etab: '',
      filliere: '',
      cin: '',
      cne: ''
    };
  }

  ngOnInit(): void {
    // Récupérer les informations du client depuis le stockage local
    const client = localStorage.getItem("user");
    if (client) {
      this.client = JSON.parse(client);
    }

    // Appeler le service pour récupérer l'historique des commandes du client
    this.clientser.history(this.client.id).subscribe(
      response => {
        // Vérifier si la réponse contient des données
        if (response && Array.isArray(response) && response.length > 0) {
          this.Commande = response; // Stocker les commandes dans la variable Commande
        } else {
          this.listvide = true; // Indiquer que la liste des commandes est vide
        }
      },
      error => {
        console.error('Erreur lors de la récupération de l\'historique :', error);
        // Vérifier s'il y a un message d'erreur provenant du serveur
        if (error && error.error && error.error.message) {
          console.error('Message d\'erreur du serveur :', error.error.message);
        }
      }
    );
  }

    // Télécharger l'attestation
    downloadFile(link: string) {
      this.CommandeService.downloadFile(link).subscribe({
        next: (data: Blob) => {
          const blob = new Blob([data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        error: (err) => {
          this.toastr.error("Attestation non trouvée !", "Erreur");
          console.error('Une erreur est survenue lors du téléchargement de l\'attestation :', err);
        }
      });
    }
}

import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from '../services/client.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-resetpass',
  templateUrl: './resetpass.component.html',
  styleUrls: ['./resetpass.component.css']
})

export class ResetpassComponent implements OnInit {
  resetPasswordForm!: FormGroup;
  token: string='';

  constructor(

    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private clientserv:ClientService,
    private toastr:ToastrService,
  ) {

  }
  ngOnInit(): void {
    //prendre le token depuis le lien 
    this.route.params.subscribe(params => {
      this.token = params['token'];
    });
    //verifier si le token est correct 
    this.clientserv.tokenchecker(this.token).subscribe(
      (response) => {
        //si le token est expiré ou incorrect
        if(response === false){
          //rederiger vers page de token expiré
          this.router.navigate(['TokenExpired',  this.token]);
        }
      },
      (error) => {
        this.toastr.error(error.error.message)
        console.error('Password reset failed', error);
      }
    );
     this.resetPasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required,Validators.maxLength(12),Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d).{6,}$/)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: this.passwordMatchValidator
    });

    // Extract the token from the route
  }
  //fonction pour verifier si l'input du mot de passe et la confirmation du mot de passe sont les memes !
  passwordMatchValidator(form: FormGroup): { [key: string]: boolean } | null {
    const newPassword = form.controls['newPassword'].value;
    const confirmPassword = form.controls['confirmPassword'].value;
  
    if (newPassword !== confirmPassword) {
      return { 'passwordMismatch': true };
    }
  
    return null;
  }
  //fonction pour envoyer la formulaire au server
  resetPassword(): void {
    //si la form est valid les mot de passes sont les memes et le pattern est valide
    if (this.resetPasswordForm.valid) {
      const newPassword = this.resetPasswordForm.controls['newPassword'].value;
      const resetData = {
        Password: newPassword,
        token: this.token
      };
      //verifier si le token est correcte
      this.clientserv.tokenchecker(this.token).subscribe(
        (response) => {
          if(response === false){
            this.router.navigate(['TokenExpired',  this.token]);
          }
          if(response === true){
            this.clientserv.change_pass(resetData ).subscribe(
              (response) => {
                this.toastr.success(response.message)
                // rediriger au page login si le mot de passe est changé avec sucees
                this.router.navigate(['']);
              },
              (error) => {
                this.toastr.error(error.error.message)
                console.error(error);
              }
            );
          }else{
            this.toastr.error("Token expired");
          
          }
        },
        (error) => {
          this.toastr.error(error.error.message)
          console.error('Password reset failed', error);
        }
      );  
  }
}}

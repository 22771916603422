import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from './services/loader.service';
import { Spinkit } from 'ng-http-loader'; // <============
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'prevasUser';
  isLoading: Subject<boolean> = this.loader.isLoading;
  public spinkit = Spinkit; 

  constructor( private loader: LoaderService) { }
  
}

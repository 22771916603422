<div class="responsive">
  <div style="display: flex;
  justify-content: center;">
    <img src="../../assets/logo-n.png" style="position: relative;
    /* top: 10%; */
    /* display: flex; */
    justify-content: center;
    width: 30%;
    ">
    </div>
   
</div>
<div class="container">
    <div class="image" style="width: 34%;">
        <div style="display: flex; justify-content: center; flex-direction: column;margin-top: 7%;">
          <div style="display: flex;
          justify-content: center;">
            <img src="../../assets/logo-n.png" style="position: relative;
            /* top: 10%; */
            /* display: flex; */
            justify-content: center;
            width: 37%;
            ">
          </div>
          <div style="margin: 12% 0;
          /* width: 65%; */
          display: flex;
          justify-content: center;">
            <img src="../../assets/Image_RC_Etudiant_PREVAS.png" style="width: 60%;
            position: relative;
            /* top: 35%; */
            /* left: 11%;*/
            "> 
          </div>
        </div>
      <div class="text">
       <div class="footer">
          <h3 style="font-weight: 500;">Vous pouvez nous contacter sur l'email suivant :</h3>
         
          <p >
            <a style="color: #cecece;text-decoration: underline;" href="mailto:reclamation@prevas.ma">reclamation@prevas.ma</a>
          </p>
          <p >
            Tél : <a style="color: #cecece;">05 22 020 903</a> / <a style="color: #cecece;">05 22 020 908</a>
          </p>
        </div>
      </div>
       </div>
       <p class="goback" routerLink="">
        <i style="color: #29234A;font-size: 19px;" class="fas fa-arrow-left"></i><a class="a">Revenir </a>
      </p>
     
<div class="form">
  
    <form action="#" method="post" [formGroup]="desactivateForm" (ngSubmit)="onformsubmit(clientForm.value)" #clientForm="ngForm">
        <h2><i style="margin: 0px 10px 0 0;
          font-size: 18px;
          padding: 6px 0;" class="fas fa-user-alt-slash"></i>Formulaire de désactivation de compte</h2>
        <div class="inputs">
           
            <div class="input-group">
                <div class="combine">
                    
                        <input appAllowedLetters class="formulaire" type="text" id="cin" formControlName="cni" [(ngModel)]="client.cni" (focus)="onchangecin()" name="Cni" required placeholder="CIN">
                        <div *ngIf="desactivateForm.get('cni')?.hasError('required') && desactivateForm.get('cni')?.touched " style="color: #da0e0e;" class="description">
                            Le numéro CIN est obligatoire
                        </div>
                        <div *ngIf="desactivateForm.get('cni')?.hasError('minlength') " style="color: #da0e0e;" class="description">
                            Le CIN doit avoir au moins 4 caractères
                        </div>
                   
                </div>
                <div class="combine">
                    <div class="bacetranger">

                        <input id="bac" type="checkbox" [checked]="bacetranger" (click)="onchangebacetranger()" >
                        <label for="bac" style="margin-top: 1px;cursor: pointer;font-size: 14px;"> Bac étranger</label>
                    </div>
                    <input *ngIf="!bacetranger" class="formulaire " (input)="limitInput($event)" formControlName="cne" type="text" (focus)="onchangecne()" id="cne" [(ngModel)]="client.cne" maxlength="10" name="Cne" required placeholder="CNE">
                    <div class="description" *ngIf="desactivateForm.get('cne')?.hasError('required') && desactivateForm.get('cne')?.touched  && !bacetranger" style="color: #da0e0e;"> Le CNE est obligatoire </div>
                    <div class="description" *ngIf="desactivateForm.get('cne')?.hasError('pattern') && desactivateForm.get('cne')?.touched  && !bacetranger" style="color: #da0e0e;">Le CNE doit comporter exactement 10 caractères
                    </div>
                    <input *ngIf="bacetranger" style="border-bottom: 1px solid #d8d3d3;border: 1px solid #f2f1f1;" class="formulaire" type="text" id="cne" name="Cne" placeholder="CNE" disabled>
              
                </div>
                  </div>
            
            
            <div class="input-group">
                <div class="combine">
                    <input class="formulaire " type="text" id="nom" appAllowedLetters formControlName="nom"(focus)="onchangenom()" [(ngModel)]="client.nom" name="Nom" required placeholder="Nom">
                    <div *ngIf="desactivateForm.get('nom')?.hasError('required') && desactivateForm.get('nom')?.touched" style="color: #da0e0e;" class="description">
                        Le nom est obligatoire
                    </div>
                    <div *ngIf="desactivateForm.get('nom')?.hasError('pattern') && desactivateForm.get('nom')?.touched " style="color: #da0e0e;" class="description">
                        Le nom ne doit contenir que des lettres, sans chiffres
                    </div>
                
                 </div>
                 <div class="combine">
                <input class="formulaire  " appAllowedLetters formControlName="prenom" type="text" (focus)="onchangeprenom()" id="prenom" [(ngModel)]="client.prenom" name="Prenom" required placeholder="Prénom"> 
                <div style="color: #da0e0e;"  id="prenom" class="description" *ngIf="desactivateForm.get('prenom')?.hasError('required') && desactivateForm.get('prenom')?.touched ">Le prénom est obligatoire
                    <!-- *ngIf="desactivateForm.get('prenom')?.invalid && desactivateForm.get('prenom')?.touched" -->
                </div>
                <div style="color: #da0e0e;"  id="prenom" class="description" *ngIf="desactivateForm.get('prenom')?.hasError('pattern') && desactivateForm.get('prenom')?.touched">Le prénom ne doit contenir que des lettres, sans chiffres
                    <!-- *ngIf="desactivateForm.get('prenom')?.invalid && desactivateForm.get('prenom')?.touched" -->
                </div>
                  </div>
                 </div>
                 <div class="input-group"> 
                   <div class="combine">
                     <input class="formulaire "  type="tel" (focus)="onchangetelephone()" id="telephone" formControlName="telephone" [(ngModel)]="client.telephone" name="Telephone" required placeholder="Téléphone">
                     <p *ngIf="desactivateForm.get('telephone')?.hasError('required') && desactivateForm.get('telephone')?.touched" style="color: #da0e0e;" id="telephone" class="description">
                       Le numéro de téléphone est obligatoire
                     </p>
                     <p *ngIf="desactivateForm.get('telephone')?.hasError('pattern') && desactivateForm.get('telephone')?.touched " style="color: #da0e0e;" id="telephone" class="description">
                      Format téléphone invalide
                     </p>
                   </div>
                </div> 
                <br>
               
 
        </div>
       
       <br>
    <div class="sub">
        <button type="submit" (click)="submitForm()"   class="button" (click)="onSubmit()">Envoyer</button>

    </div>
    
      </form>
</div>
 

</div>
<div class="responsive" style="background: transparent;padding: 0;">
  
  <p style="color: #000000;
       margin: 10px 20px;
       font-size: 14px;
       font-weight: 600;">Vous pouvez nous contacter sur l'email suivant: <a href="mailto:reclamation@prevas.ma" style="font-size: 14px;">reclamation@prevas.ma</a></p>
      <p style="color: #000000;
      margin: 5px 20px;
      font-size: 14px;
      font-weight: 600;">
        Tél : <a style="font-size: 14px;">05 22 020 903</a> / <a style="font-size: 14px;">05 22 020 908</a>
      </p>
</div>

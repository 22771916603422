import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  
  private baseUrl: string = `${environment.apiUrl}/Clients/`;
  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();
  constructor(private http:HttpClient
    ) {  }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }
  signIn(loginObj : any){
    return this.http.post<any>(`${this.baseUrl}Login`,loginObj)
  }
  history(idclient:string){
    return this.http.get<any>(`${environment.apiUrl}/Commandes/Historique/${idclient}`)

  }

  testtest(obj){
    const formData: FormData = new FormData();
    formData.append('code', "5465446");
    formData.append('externalId', "dfdfgfg");
    formData.append('expirationDate', "fdfdgfdg");
    formData.append('amount', "dfdffd");
    formData.append('buyerFirstName', "fgdfdfdgffgd");
    formData.append('buyerLastName', "dfdfdf");
    formData.append('buyerEmail', "fddf");
    formData.append('buyerAddress', "fdfddf");
    formData.append('buyerPhone', "fddfdf");
    formData.append('orderCheckSum', "fddfdf");

    return this.http.post<any>(`${environment.apiUrl}/Commandes/VerifyCashPayment0`, formData, {

      
    });
  }
  storeToken(tokenValue: any){


    localStorage.setItem('UserId', tokenValue.user)

    if (localStorage.getItem('rememberMe') === "true") {
      localStorage.setItem('token', JSON.stringify(tokenValue) )
    } else {
      localStorage.setItem('token', JSON.stringify(tokenValue))

    }
    
    
    
    
  }
  getclientbyid(idclient:string){
    return this.http.get<any>(`${environment.apiUrl}/Clients/getbyidTwo/${idclient}`)
  }
  public updateclient(client: any,clientId:string): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/Clients/updateinfostudent/${clientId}`,
      client
    );}

    sendresetpass(email: string) {
      const body = { email: email }; // Assuming the server expects an object with an "email" field
      const headers = { 'Content-Type': 'application/json' };
    
      return this.http.post<any>(
        `${environment.apiUrl}/Clients/passwordRequestchange`,
        body,
        { headers }
      );
    }
    desactivationrequest(client:any){
      return this.http.post<any>(
        `${environment.apiUrl}/DesactiveateRequest/newdemandedesactivate`,
         client
      );
    }

    register(client:any){
      return this.http.post<any>(
        `${environment.apiUrl}/Clients/register`,
         client
      );
    }

    change_pass(data:any){
      return this.http.post<any>(`${environment.apiUrl}/Clients/change-pass`,data);
    }
    
  
    public newdemande(client: any,clientId:any): Observable<any> {
      return this.http.post<any>(
        `${environment.apiUrl}/RequestChangeinfo/NewDemandeChangeInfo/${clientId}`,
        client
      );}

      tokenchecker(token:any){
        return this.http.post<any>(`${environment.apiUrl}/Clients/checked/${token}`,token);

      }


      // otp
      sendOtp(email: string) {
        return this.http.post(`${environment.apiUrl}/Auth/send-otp`, { email });
      }
    
      verifyOtp(email: string, otpCode: string) {
        return this.http.post(`${environment.apiUrl}/Auth/verify-otp`, { email, otpCode });
      }
}

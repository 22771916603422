<div class="container">
  <div class="image" style="width: 34%;">
    <div style="display: flex; justify-content: center; flex-direction: column;margin-top: 7%;">
      <div style="display: flex;
      justify-content: center;">
        <img src="../../assets/logo-n.png" style="position: relative;
        /* top: 10%; */
        /* display: flex; */
        justify-content: center;
        width: 37%;
        ">
      </div>
      <div style="margin: 12% 0;
      /* width: 65%; */
      display: flex;
      justify-content: center;">
        <img src="../../assets/Image_RC_Etudiant_PREVAS.png" style="width: 60%;
        position: relative;
        /* top: 35%; */
        /* left: 11%;*/
        "> 
      </div>
    </div>
  <div class="text">
    <div class="footer">
      <h3 style="font-weight: 500;">Vous pouvez nous contacter sur l'email suivant :</h3>
     
      <p >
        <a style="color: #cecece;text-decoration: underline;" href="mailto:reclamation@prevas.ma">reclamation@prevas.ma</a>
      </p>
      <p >
        Tél : <a style="color: #cecece;">05 22 020 903</a> / <a style="color: #cecece;">05 22 020 908</a>
      </p>
    </div>
  </div>
   </div>
   <div class="responsive">
    <div style="display: flex;
    justify-content: center;">
      <img src="../../assets/logo-n.png" style="position: relative;
      /* top: 10%; */
      /* display: flex; */
      justify-content: center;
      width: 30%;
      ">
      </div>
     
  </div>
<div class="contain hidden" id="recovery-menu">
    <div class="holder">
        <h2><i style="margin: 0px 10px 0 0;
          font-size: 18px;
          padding: 6px 0;" class="fas fa-exclamation-triangle"></i>Problèmes de connexion ?</h2>
        <p style="color: #29234A;margin: 41px 0;">Entrez votre adresse e-mail et nous vous enverrons un lien pour récupérer votre compte.</p>
        <form (ngSubmit)="onFormSubmit()" [formGroup]="UserForm">
          <div class="content">
            <input type="email" formControlName="email" id="email" name="email" required placeholder="exemple@gmail.com">
            <div *ngIf="UserForm">

              <p style="color: red;font-size: 14px;" *ngIf="UserForm.get('email')?.invalid && UserForm.get('email')?.touched" class="alert alert-danger alert-ysn">
                Email invalid
              </p>
            </div>
            <div style="display: flex;justify-content: end;">
              <button  class="button" type="submit" (click)="onSubmit()">Envoyer un lien de connexion</button>

            </div>
          </div>
        </form>
        <p class="goback" routerLink="">
          <i style="color: #29234A;font-size: 19px;" class="fas fa-arrow-left"></i><a class="a">Revenir </a>
        </p>
    </div>
   
  </div>
  <div class="responsive" style="background: transparent;padding: 0;">
    <p style="color: #000000;
         margin: 10px 31px;
         font-size: 14px;
         font-weight: 600;">Vous pouvez nous contacter sur l'email suivant: <a href="mailto:reclamation@prevas.ma" style="font-size: 14px;">reclamation@prevas.ma</a></p>
        <p style="color: #000000;
        margin: 5px 31px;
        font-size: 14px;
        font-weight: 600;">
          Tél : <a style="font-size: 14px;">05 22 020 903</a> / <a style="font-size: 14px;">05 22 020 908</a>
        </p>
 </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { event } from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { ReclamationService } from 'src/app/services/reclamation.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  // Form group pour le formulaire de réclamation
  reclamation: FormGroup = new FormGroup({});
  cne: string = '';
  submitted  =  false;

  // Indicateur pour savoir si le champ CNE est vide
  emptyfield: boolean = false;

  constructor(private formBuilder: FormBuilder, private reclamservice: ReclamationService, private toastr: ToastrService) {
    if (this.emptyfield == false) {
      // Initialiser le formulaire avec les règles de validation lorsque le champ CNE n'est pas vide
      this.reclamation = this.formBuilder.group({
        cin: ['', [Validators.required, Validators.minLength(4)]],
        cne: ['', [Validators.minLength(10), Validators.pattern('^[a-zA-Z0-9]{10}$')]],
        description: ['', []],
        nom: ['', [Validators.required, Validators.pattern('^[a-zA-Z\\s\\-]+$')]],
        prenom: ['', [Validators.required, Validators.pattern('^[a-zA-Z\\s\\-]+$')]],
        telephone: ['', [Validators.required, Validators.minLength(10)]],
        objet: ['', [Validators.required, this.radioRequiredValidator()]],
        email: ['', [Validators.required, Validators.email]],
      });
    }
    if (this.emptyfield == true) {
      // Initialiser le formulaire sans validation pour le champ CNE lorsqu'il est vide
      this.reclamation = this.formBuilder.group({
        cin: ['', [Validators.required, Validators.minLength(4)]],
        description: ['', []],
        cne: ['', []],
        nom: ['', [Validators.required, Validators.pattern('^[a-zA-Z\\s\\-]+$')]],
        prenom: ['', [Validators.required, Validators.pattern('^[a-zA-Z\\s\\-]+$')]],
        telephone: ['', [Validators.required, Validators.minLength(10)]],
        objet: ['', [Validators.required, this.radioRequiredValidator()]],
        email: ['', [Validators.required, Validators.email]],
      });
    }
  }

  ngOnInit(): void {
    this.cneChange;
  }

  // Méthode pour gérer les changements dans le champ CNE
  cneChange(event: Event) {
    const cneInput = event.target as HTMLInputElement;
    const cne = cneInput.value;
    this.cne = cne;
    //detecter si le champ n'est pas vide pour appliquer 
    if (cne !== null && cne !== undefined) {
      this.emptyfield = cne.length > 0 ? false : true;
    }
  }

  // Méthode pour gérer la soumission du formulaire
  onFormSubmit() {
    if (this.reclamation.valid) {
      // Envoyer les données de réclamation au serveur si le formulaire est valide
      this.reclamservice.newReclamation(this.reclamation.value).subscribe(
        (response: any) => {
          this.toastr.success(response.message, "Succès");
          this.reclamation.reset();
          this.reclamation.markAsUntouched();
        },
        (error: any) => {
          this.toastr.error(error.error.message);
        }
      );
    } else {
      this.toastr.warning("Veuillez remplir tous les champs obligatoires.");
    }
  }

  // Validateur personnalisé pour vérifier si le bouton radio est requis
  radioRequiredValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      return value === null || value === undefined ? { 'required': true } : null;
    };
  }

  // Méthodes pour marquer des contrôles de formulaire spécifiques comme touchés
  onchangecin() {
    this.reclamation.get('cin')?.markAsTouched();
  }

  onchangecne() {
    this.reclamation.get('cne')?.markAsTouched();
  }

  onchangenom() {
    this.reclamation.get('nom')?.markAsTouched();
  }

  onchangeprenom() {
    this.reclamation.get('prenom')?.markAsTouched();
  }

  onchangetelephone() {
    this.reclamation.get('telephone')?.markAsTouched();
  }

  onchangeemail() {
    this.reclamation.get('email')?.markAsTouched();
  }
  onSubmit() {
    this.submitted = true;

  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accountverified',
  templateUrl: './accountverified.component.html',
  styleUrls: ['./accountverified.component.css']
})
export class AccountverifiedComponent implements OnInit {
ngOnInit(): void {
  
  // alert("ff")
}
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommandeService {

  constructor(
   private http:HttpClient
  ) { }


    cardpayment(commande:any){
      // return this.http.post<any>(`${environment.apiUrl}/Commandes/paymentbycard`,commande)
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.http.post(`${environment.apiUrl}/Commandes/paymentbycard`,commande, { headers, responseType: 'text' });
    }
    Cashpayment(commande:any){
      return this.http.post<any>(`${environment.apiUrl}/Commandes/cashPrePayment`,commande)
    }
    downloadFile(link:string) {
      let url = (`${environment.apiUrl}/Attestation/DownloadAtt/${link}`);
      return this.http.get(url, { responseType: 'blob' });
    }
}

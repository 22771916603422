import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-desactiver-compte',
  templateUrl: './desactiver-compte.component.html',
  styleUrls: ['./desactiver-compte.component.css']
})
export class DesactiverCompteComponent implements OnInit {
  client: any;
  submitted  =  false;

  // Formulaire de désactivation du compte
  desactivateForm!: FormGroup;
  // Indicateur pour vérifier si le client est un bachelier étranger
  bacetranger: boolean = false;

  constructor(
    private clientserv: ClientService,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {
    // Initialiser le formulaire avec les règles de validation
    this.desactivateForm = fb.group({
      cni: ['', [Validators.required, Validators.minLength(4)]],
      cne: ['', this.bacetranger ? [] : [Validators.required, Validators.pattern('^[a-zA-Z0-9]{10}$')]],
      nom: ['', [Validators.required, Validators.pattern('^[a-zA-Z\\s\\-]+$')]],
      prenom: ['', [Validators.required, Validators.pattern('^[a-zA-Z\\s\\-]+$')]],
      // cne: ['', [Validators.minLength(10), Validators.pattern('^[a-zA-Z0-9]{10}$')]],

      telephone: ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
    });

    // Initialiser l'objet client avec des valeurs vides
    this.client = {
      nom: '',
      prenom: '',
      cni: '',
      cne: '',
      telephone: '',
    };
  }

  ngOnInit(): void {

   }

  // Méthode pour gérer la soumission du formulaire
  onformsubmit(client: any) {
    if (this.desactivateForm.valid) {
      // Vérifier si le client est un bachelier étranger et supprimer le champ CNE si nécessaire
      if (this.bacetranger) {
        delete client.cne;
      }

      // Envoyer la demande de désactivation au service client
      this.client = client;
      this.clientserv.desactivationrequest(client).subscribe(
        response => {
          this.toastr.success(response.message);
          this.desactivateForm.reset();
          this.desactivateForm.markAsUntouched();
        },
        error => {
          if (error && error.error && error.error.message) {
            this.toastr.info(error.error.message, 'Infot :');
          }
        }
      );
    }
  }

  // Méthode pour changer l'état du bachelier étranger et mettre à jour les validateurs de champ CNE en conséquence
  onchangebacetranger() {
    this.bacetranger = !this.bacetranger;

    if (this.bacetranger) {
      this.desactivateForm.get('cne')?.clearValidators(); // Supprimer les validateurs
    } else {
      this.desactivateForm.get('cne')?.setValidators([Validators.required, Validators.pattern('^[a-zA-Z0-9]{10}$')]); // Ajouter les validateurs
    }
    this.desactivateForm.get('cne')?.updateValueAndValidity();
  }

  // Méthodes pour marquer des contrôles de formulaire spécifiques comme touchés
  onchangecin() {
    this.desactivateForm.get('cni')?.markAsTouched();
  }

  onchangecne() {
    this.desactivateForm.get('cne')?.markAsTouched();
  }

  onchangenom() {
    this.desactivateForm.get('nom')?.markAsTouched();
  }

  onchangeprenom() {
    this.desactivateForm.get('prenom')?.markAsTouched();
  }

   onchangetelephone() {
     this.desactivateForm.get('telephone')?.markAsTouched();
   }

  onchangeemail() {
    this.desactivateForm.get('email')?.markAsTouched();
  }

  // Méthode pour limiter la longueur de l'entrée dans un champ de texte
  limitInput(event: any) {
    const maxLength = 15;
    const input = event.target as HTMLInputElement;
    if (input.value.length > maxLength) {
      input.value = input.value.slice(0, maxLength);
    }
  }

  // Méthode pour marquer tous les contrôles de formulaire comme touchés
  submitForm() {
    this.desactivateForm.markAllAsTouched();
  }
  onSubmit() {
    this.submitted = true;

  }
}

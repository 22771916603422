<app-sidebar></app-sidebar>
<div class="all">
    <p routerLink="/commander" class="back_title"><i style="color: #29234A;font-size: 15px;" class="fas fa-arrow-left"></i>
        <a class="goback">Revenir à l'accueil</a></p>
    <div class="image">
        <img src="./../../../assets/logo_cmi.png">
    </div>
    
    <div class="">
        <div class="fail">
           <h3>Paiement échoué</h3>
            
        </div>
       
        <div class="price">
            Montant payée : <strong> $request->amount  DH</strong>
        </div>
        <h4 class="fail">Le paiement a échoué, merci de vérifier les informations de votre carte.</h4>
        <h3>Récapitulatif</h3>
       
        <table >
            <tr>
                <td><strong>Commande&nbsp;</strong></td>
                <td>$request->oid</td>
            </tr>
            <tr>
                <td><strong>Nom Prénom bénéficiaire&nbsp;</strong></td>
                <td>$request->BillToName</td>
            </tr>
            <tr>
                <td><strong>N de la piece d'identité&nbsp;</strong></td>
                <td>$cin </td>
            </tr>
            <tr>
                <td><strong>Status de paiement&nbsp;&nbsp;</strong></td>
                <td>Payée</td>
            </tr>
        
            <tr>
                <td><strong>Filière&nbsp;</strong></td>
                <td></td>
            </tr>
            <tr>
                <td><strong>Niveau d'étude&nbsp;</strong></td>
                <td></td>
            </tr>
        
        </table>
    </div>
    
    
</div>

<app-sidebar></app-sidebar>
<body>
    <form [formGroup]="clientform" id="personalInfoForm"  #clientForm="ngForm" >
      <h2><i style="margin: 0px 10px 0 0;
        font-size: 18px;
        padding: 6px 0;" class="fas fa-info-circle"></i>Informations personnelles</h2>
     <div class="cne">
      <input style="height: auto;margin: 2px 6px 0 0;" appAllowedLetters id="bac" type="checkbox" [checked]="bacetranger" (click)="onchangebacetranger($event)">
      <label for="bac" style="text-transform: uppercase;color: #363535;position: static;">Bac étranger</label>
    </div>
     <div class="form-row">

        <div class="input-data">
          <label for="CIN">CIN</label>
          <input type="text" formControlName="cin" name="CIN" required>
          <p *ngIf="clientform.get('cin')?.hasError('required') && submitted" class="description">Le numéro CIN est obligatoire</p>
          <p *ngIf="clientform.get('cin')?.hasError('minLength') && submitted" class="description"> Le CIN doit avoir au moins 4 caractères</p>

        </div>
        
        <div class="input-data">
          
          <label  for="CNE">CNE</label>
          <input style="background: rgb(223 223 223 / 10%); border: 1px solid #f5f3f3;" *ngIf="bacetranger" type="text" name="cne" class="input" disabled>
          <input *ngIf="!bacetranger" (change)="cneChange($event)" maxlength="10" type="text" formControlName="cne" name="CNE" >
          <p  *ngIf="clientform.get('cne')?.hasError('required') && submitted" class="description">Le CNE est obligatoire</p>
          <p  *ngIf="clientform.get('cne')?.hasError('pattern') && submitted" class="description">Le CNE doit comporter exactement 10 caractères</p>
        </div>
      </div>
    
      <div class="form-row">
        <div class="input-data">
          <label for="NOM">NOM</label>
          <input type="text"formControlName="nom" name="NOM" required [readonly]="commandepaid">
          <p *ngIf="clientform.get('nom')?.hasError('required') && submitted" class="description">Le nom est obligatoire</p>
          <div *ngIf="clientform.get('nom')?.hasError('pattern') && submitted" class="description">
            Le nom ne doit contenir que des lettres, sans chiffres
        </div>
        </div>
        <div class="input-data">
          <label for="PRENOM">PRÉNOM</label>
          <input type="text" formControlName="prenom" name="PRENOM" required [readonly]="commandepaid" >
          <div class="description" *ngIf="clientform.get('prenom')?.hasError('required') && submitted">Le prénom est obligatoire
                
          </div>
          <div class="description" *ngIf="clientform.get('prenom')?.hasError('pattern') && submitted">Le prénom ne doit contenir que des lettres, sans chiffres
              
          </div> 
        </div>
      </div>
    
      <div class="form-row">
        <div class="input-data">
          <label for="EMAIL">EMAIL</label>
          <input type="text" formControlName="email"  name="EMAIL" required [readonly]="true">
          <p *ngIf="clientform.get('email')?.hasError('email') && submitted" class="description"> 
            L'adresse e-mail que vous avez saisie n'est pas une adresse e-mail valide.</p>
          <p *ngIf="clientform.get('email')?.hasError('required') && submitted" class="description"> 
            L'adresse e-mail est obligatoire</p>
        </div>
        <div class="input-data">
          <label for="TELEPHONE">TÉLÉPHONE</label>
          <input type="text"formControlName="telephone"name="TELEPHONE" required   (keypress)="restrictNonNumeric($event)" >
          <p *ngIf="clientform.get('telephone')?.hasError('required') && submitted"  class="description">
            Le numéro de téléphone est obligatoire
        </p>
        <p *ngIf="clientform.get('telephone')?.hasError('pattern') && submitted" class="description">Le numéro de téléphone doit commencer par 0 suivi de 9 chiffres</p>

        <!-- <p *ngIf="clientform.get('telephone')?.hasError('minlength') && submitted" class="description">
          Le numéro téléphone doit avoir au moins 10 caractères
        </p> -->
        </div>
      </div>
    
      <div class="form-row">
        <div class="input-data">
          <label for="ADRESSE">ADRESSE</label>
          <input type="text" formControlName="adresse" name="ADRESSE" required>
          <p *ngIf="clientform.get('adresse')?.invalid && submitted" class="description"> L'adresse est obligatoire</p>

        </div >
        <div class="input-data">
          <label for="ville">VILLE</label>
          
          <mat-form-field appearance="fill" style="border: 1px solid #d8d8d8;
          border-radius: 4px;
          background: rgb(192 192 192 / 10%);
          outline: none;
          height: 36px;
          padding-top: 12px;
          margin-top: 4px;
          width: 100%;
          margin-left: -2%;">
            <mat-select style="font-family: 'Noir Pro' , sans-serif;" formControlName="ville" placeholder="Sélectionnez votre ville">
              <mat-option>
                <ngx-mat-select-search [(ngModel)]="searchTerm" placeholderLabel="Recherche..." noEntriesFoundLabel="'Aucune ville trouvée'"
                 [ngModelOptions]="{standalone: true}" (input)="searchCities()">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let city of listville  | async" [value]="city.ville" style="font-family: 'Noir Pro'">
                {{ city.ville }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          
          <p *ngIf="clientform.get('ville')?.invalid && submitted" class="description">
            Veuillez selectioner Votre ville
          </p>
        </div>
        
        
        
        
      </div>
    
      <button type="submit" [class.disable]="!clientform.valid" class="button"  (click)="onFormSubmit();onSubmit()" *ngIf="!commandepaid">Enregistrer ma modification</button>
      <button type="submit"[class.disable]="!clientform.valid" class="button" *ngIf="commandepaid" (click)="onFormSubmit2(clientForm.value);onSubmit()">Demande de modification</button>
    
      <div class="footer">
        
        <p class="p">
          Vous pouvez nous contacter sur l'email suivant : <a href="mailto:reclamation@prevas.ma">reclamation@prevas.ma</a>
        </p>
      </div>
    </form>
    
    
    
    
    </body>
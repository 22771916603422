import { Component } from '@angular/core';

@Component({
  selector: 'app-cmi-error',
  templateUrl: './cmi-error.component.html',
  styleUrls: ['./cmi-error.component.css']
})
export class CmiErrorComponent {

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  client: any;
  Commande: any;
  hidetype: boolean = false;
  someCommandHasStatusTrue: boolean = false;
  listvide: boolean = false;
  iClick: boolean = false;
  Clicked: boolean = false;

  constructor(
    private router: Router,
    private clientser: ClientService
  ) {}

  // Méthode appelée lors de l'initialisation du composant
  ngOnInit(): void {
    const payee = localStorage.getItem("payee");

    // Vérifie si la commande a été payée
    if (payee == "true") {
      this.hidetype = true;
    } else {
      this.hidetype = false;
    }

  }

  // Méthode pour indiquer que le composant a été cliqué
  isClicked() {
    this.iClick = true;
    this.Clicked = false;
  }

  // Méthode pour indiquer que le composant n'est plus cliqué
  notClicked() {
    this.Clicked = true;
    this.iClick = false;
  }

  // Méthode pour vérifier si une route est active
  isRouteActive(route: string): boolean {
    return this.router.isActive(route, true);
  }

  // Méthode pour se déconnecter et nettoyer le localStorage
  deconnexion() {
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }
}

<div class="container">
    <div class="image" style="width: 34%;">
        <div style="display: flex; justify-content: center; flex-direction: column;margin-top: 7%;">
          <div style="display: flex;
          justify-content: center;">
            <img src="../../assets/logo-n.png" style="position: relative;
            /* top: 10%; */
            /* display: flex; */
            justify-content: center;
            width: 37%;
            ">
          </div>
          <div style="margin: 12% 0;
          /* width: 65%; */
          display: flex;
          justify-content: center;">
            <img src="../../assets/Image_RC_Etudiant_PREVAS.png" style="width: 60%;
            position: relative;
            /* top: 35%; */
            /* left: 11%;*/
            "> 
          </div>
        </div>
      <div class="text">
        <div class="footer">
          <h3 style="font-weight: 500;">Vous pouvez nous contacter sur l'email suivant :</h3>
         
          <p class="p">
            <a style="color: #cecece;text-decoration: underline;" href="mailto:reclamation@prevas.ma">reclamation@prevas.ma</a>
          </p>
          <p class="p">
            Tél : <a style="color: #cecece;">05 22 020 903</a> / <a style="color: #cecece;">05 22 020 908</a>
          </p>
        </div>
      </div>
       </div>
       <div class="responsive">
        <div style="display: flex;
        justify-content: center;">
          <img src="../../assets/logo-n.png" style="position: relative;
          /* top: 10%; */
          /* display: flex; */
          justify-content: center;
          width: 30%;
          ">
          </div>
         
      </div>
<div class="form">
    <form action="#" method="post" [formGroup]="reclamation" (ngSubmit)="onFormSubmit( )" #reclamationForm="ngForm" [formGroup]="reclamation">
        <h2><i style="margin: 0px 10px 0 0;
            font-size: 18px;
            padding: 6px 0;" class="fas fa-exclamation-triangle"></i>Formulaire de réclamation</h2>
        <div class="inputs">
            <div class="input-group">
                <div class="join">
                    <input appAllowedLetters class="formulaire" type="text" id="cin" formControlName="cin" (focus)="onchangecin()" name="cin" required placeholder="CIN">
                    <p *ngIf="reclamation.get('cin')?.hasError('required') && reclamation.get('cin')?.touched">
                       Le numéro CIN est obligatoire
                        
                        </p>
                    <p *ngIf="reclamation.get('cin')?.hasError('minlength') && reclamation.get('cin')?.touched">Le numéro CIN doit avoir au moins 4 caractères</p>

                </div><br>
                <div class="join">
                    <input appAllowedLetters class="formulaire margin" type="text" id="cne"  (change)="cneChange($event)" maxlength="10" (focus)="onchangecne()" formControlName="cne" name="cne" placeholder="CNE">
                    
                    
                        <!-- Display error messages for cne when it's not empty -->
                        <!-- <p *ngIf="reclamation.get('cne')?.hasError('minlength') && emptyfield === false ">CNE doit avoir au moins 10 numéros</p> -->
                        
                        <p *ngIf="reclamation.get('cne')?.hasError('pattern') && emptyfield === false">Le numéro CNE doit comporter exactement 10 caractères</p>
                    </div>
            </div>
            <div class="input-group">
                <div class="join">
                    <input appAllowedLetters class="formulaire" type="text" id="nom" (focus)="onchangenom()" formControlName="nom" name="nom" required placeholder="Nom">
                    <p *ngIf="reclamation.get('nom')?.hasError('required') && reclamation.get('nom')?.touched" >
                        Le nom est obligatoire
                        
                        </p>
                    
                    <p *ngIf="reclamation.get('nom')?.hasError('pattern') && reclamation.get('nom')?.touched" >Le nom ne doit contenir que des lettres, sans chiffres</p>
                </div>
                <br>
            
               <div class="join">
                <input appAllowedLetters class="formulaire" type="text" id="prenom"(focus)="onchangeprenom()" formControlName="prenom" name="prenom" required placeholder="Prénom"> 
                <p *ngIf="reclamation.get('prenom')?.hasError('required') && reclamation.get('prenom')?.touched">
                    Le prénom est obligatoire
                    
                    </p>
                <p *ngIf="reclamation.get('prenom')?.hasError('pattern') && reclamation.get('prenom')?.touched">Le prénom ne doit contenir que des lettres, sans chiffres</p><br>
            </div>

               </div>
                <div class="input-group">
                    <div class="join">
                        <input class="formulaire" type="email" (focus)="onchangeemail()" id="email" formControlName="email" name="email" required placeholder="Email">
                        <p *ngIf="reclamation.get('email')?.hasError('required') && reclamation.get('email')?.touched">L'adresse e-mail est obligatoire.</p><br>
                        <p *ngIf="reclamation.get('email')?.hasError('email') && reclamation.get('email')?.touched">L'adresse e-mail que vous avez saisie n'est pas une adresse e-mail valide.</p>

                    </div><br>
                    <div class="join">
                        <input class="formulaire" type="tel"(focus)="onchangetelephone()" id="telephone"formControlName="telephone" name="telephone" required placeholder="Téléphone">
                        <p *ngIf="reclamation.get('telephone')?.hasError('required') && reclamation.get('telephone')?.touched">Le numéro de téléphone est obligatoire</p>
                        <p *ngIf="reclamation.get('telephone')?.hasError('minlength') && reclamation.get('telephone')?.touched">Le numéro téléphone doit avoir au moins 10 caractères
                        </p>
                        
                    </div>
                </div>
        </div>
        <br>
    <div class="radio" [class.invalid]="reclamation.get('objet')?.invalid && reclamation.get('objet')?.touched">
        <label class="objet" >Objet:</label><br>
        <input type="radio" id="attestation_erronee" formControlName="objet" name="objet" formControlName="objet" value="attestation_erronee">
        <label for="attestation_erronee">Attestation érronée</label><br>
        
        <input type="radio" id="attestation_non_recu" name="objet"formControlName="objet" value="attestation_non_recu">
        <label for="attestation_non_recu">Attestation non reçue</label><br>
        
        <input type="radio" id="assurance_invalide" name="objet"formControlName="objet" value="assurance_invalide">
        <label for="assurance_invalide">Assurance invalide sur le site de l'université </label><br>
        
        <input type="radio" id="infos_incorrectes" name="objet"formControlName="objet" value="infos_incorrectes">
        <label for="infos_incorrectes">Informations personnelles incorrectes</label><br>
        
        <input type="radio" id="probleme_inscription" name="objet"formControlName="objet" value="probleme_inscription">
        <label for="probleme_inscription">Problème d'inscription</label><br>
        
        <input type="radio" id="autre" name="objet"formControlName="objet" value="autre">
        <label for="autre">Autre</label>
        <div *ngIf="reclamation.get('objet')?.hasError('required') && reclamation.get('objet')?.touched" class="validation-message" style="color: red;">
            Veuillez sélectionner l'une des options.
        </div>
    </div>
       <br>
    
    <div class="description">
        <!-- <label for="description"></label><br> -->
        <textarea appAllowedLetters id="description" name="description" formControlName="description"  rows="4" cols="50"
        placeholder="Mettez votre description ici:"></textarea><br>
    </div>
    <div class="sub">
        <button type="submit"  class="button" (click)="onSubmit()">Envoyer</button>

    </div>
    <p class="goback" routerLink="">
        <i style="color: #29234A;font-size: 19px;" class="fas fa-arrow-left"></i><a class="a">Revenir </a>
      </p>
      </form>
      <div class="responsive" style="background: transparent;padding: 0;">
        <p style="color: #000000;
             margin: 10px 8px;
             font-size: 14px;
             font-weight: 600;">Vous pouvez nous contacter sur l'email suivant: <a href="mailto:reclamation@prevas.ma" style="font-size: 14px;">reclamation@prevas.ma</a></p>
            <p style="color: #000000;
            margin: 5px 8px;
            font-size: 14px;
            font-weight: 600;">
              Tél : <a style="font-size: 14px;">05 22 020 903</a> / <a style="font-size: 14px;">05 22 020 908</a>
            </p>
     </div>
</div>
 

</div>


import { Directive, HostListener, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appAllowedLetters]'
})
export class AllowedLettersDirective {

  private allowedCharacters = /^[a-zA-Z0-9\s\u00C0-\u00FF`~!@#$%^&*()_\-+=[\]{}|;:'",.<>/?\\]*$/;
    constructor(@Optional() @Self() private ngControl: NgControl) {}

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const cleanedValue = input.value.split('').filter(char => this.allowedCharacters.test(char)).join('');

    if (input.value !== cleanedValue && this.ngControl && this.ngControl.control) {
      this.ngControl.control.setValue(cleanedValue);
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!this.allowedCharacters.test(event.key) && event.key.length === 1) {
      event.preventDefault();
    }
  }
}
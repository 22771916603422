
<div class="">
<div class="image" style="width: 34%;">
    <div style="display: flex; justify-content: center; flex-direction: column;margin-top: 7%;">
      <div style="display: flex;
      justify-content: center;">
        <img src="../../assets/logo-n.png" style="position: relative;
        /* top: 10%; */
        /* display: flex; */
        justify-content: center;
        width: 37%;
        ">
      </div>
      <div style="margin: 12% 0;
      /* width: 65%; */
      display: flex;
      justify-content: center;">
        <img src="../../assets/Image_RC_Etudiant_PREVAS.png" style="width: 60%;
        position: relative;
        /* top: 35%; */
        /* left: 11%;*/
        "> 
      </div>
    </div>
  <div class="text">
    <div class="footer">
      <h3>Contactez-nous :</h3>
      <p class="p">
        Tél : <a style="color: #cecece;">05 22 020 903</a> / <a style="color: #cecece;">05 22 020 908</a>
      </p>
      <p class="p">
        Email : <a style="color: #cecece;" href="mailto:reclamation@prevas.ma">reclamation@prevas.ma</a>
      </p>
    </div>
  </div>
   </div>

   <main>
    <p class="goback" routerLink="">
        <i style="color: #29234A;font-size: 19px;" class="fas fa-arrow-left"></i><a class="a">Revenir </a>
      </p>
    <h1 >
    <i class="fas fa-question-circle"></i>
    Questions Fréquemment Posées</h1>
    <div class="topic" *ngFor="let item of faqItems" [class.expanded]="item.expanded">
      <div class="open" (click)="toggleAnswer(item)">
        <h2 class="question">{{ item.question }}</h2>
        <span class="faq-t" [class.faq-o]="item.expanded"></span>
      </div>
      <p class="answer" [style.display]="item.expanded ? 'block' : 'none'">{{ item.answer }}</p>
    </div>
  </main>
</div>
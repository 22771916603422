<div>
  <app-sidebar></app-sidebar>
  <h2><i style="margin: 0px 10px 0 0;
    font-size: 18px;
    padding: 6px 0;" class="fas fa-history"></i>Historique des commandes</h2>   
  <table>
    <thead> 
    <tr>
    <th>Nom bénéficiaire</th>
    <th>Prénom bénéficiaire</th>
    <th>Numéro CIN</th>
    <th>Numéro CNE</th>
    <th>Status de paiement</th>
    <th>N° Police</th>
    <th>Date d'effet</th>
    <th>Date d'échéance</th>
    <th>État</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of Commande">
    <td data-title="Nom">{{client.nom}}</td>
    <td data-title="Prénom bénéficiaire">{{client.prenom}}</td>
    <td data-title="Numéro de la pièce d'identité">&nbsp;{{client.cin}}</td>
    <td data-title="Numéro de la pièce d'identité" *ngIf="client.cne">&nbsp;{{client.cne}}</td>
    <td data-title="Numéro de la pièce d'identité" *ngIf="!client.cne">&nbsp;-</td>
    <td data-title="Status de paiement">{{item.payee ? 'Payé' : 'Non payé'}}</td>
    <td data-title="N° Police" *ngIf="item.numpoli">{{item.numpoli}}</td>
    <td data-title="N° Police" *ngIf="!item.numpoli">-</td>
    <td data-title="Date d'effet">{{item.dateeffe}}</td>
    <td data-title="Date d'échéance">{{item.dateecheance}}</td>
    <td *ngIf="item.status === 'Approved' && item.payee" data-title="État" ><Button style="background-color: #29234A;
      color: white;
      border-radius: 5px;
      cursor: pointer;
      padding: 10px 20px;
      border: none;
      font-family: 'Noir Pro';" (click)="downloadFile(item.commandId)" >Telecharger</Button></td>
    <td *ngIf="!item.payee" data-title="État" >-</td>
    <td *ngIf="item.payee && !(item.status === 'Approved')" data-title="État" >En cours de traitement</td>
    </tr>
  
    </tbody>
  </table>
  <p *ngIf="listvide" style="display: flex; justify-content: center;margin-left: 10%;">Vous n'avez aucune commande</p>
</div>
<div class="footer">
 
  <p>
    Vous pouvez nous contacter sur l'email suivant: : <a href="mailto:reclamation@prevas.ma">reclamation@prevas.ma</a>
  </p>
</div>
  
  
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent {
  email: any;
  UserForm!: any;
  submitted:boolean=false;

  constructor(
    private clientserv: ClientService,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {
    // Initialisation du formulaire avec un champ email qui est requis et doit être une adresse email valide
    this.UserForm = this.fb.group({
      email: ['', [Validators.email, Validators.required]]
    });
  }

  // Fonction appelée lors de la soumission du formulaire
  onFormSubmit() {
    if (this.UserForm.valid) {
      // Extraction de la valeur de l'email depuis le formulaire
      const email = this.UserForm.get('email').value;

      // Appel au service pour envoyer une demande de réinitialisation de mot de passe
      this.clientserv.sendresetpass(email).subscribe(
        response => {
          // Affichage d'un message de succès si la demande a été envoyée avec succès
          this.toastr.success(response.message);
          if(response.message){
            this.UserForm.markAsUntouched();
            this.UserForm.reset();
          }
        },
        error => {
          // Affichage d'un message d'erreur si une erreur s'est produite lors de l'envoi de la demande
          this.toastr.info(error.error.message)
                    // console.error(error);
          // if (error && error.error && error.error.message) {
          //   this.toastr.info('info:', error.error.message);
          // }
        }
      );
    } else {
      // Le formulaire est invalide, affichage d'un message d'erreur
      this.toastr.error('Formulaire invalide!', 'Erreur');
    }
  }
  onSubmit() {
    this.submitted = true;

  }
}

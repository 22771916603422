import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService } from 'src/app/services/client.service';
import { VilleService } from 'src/app/services/ville.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit{
  loop1: any = undefined;
  loop2: any = undefined;
  loop3: any = undefined;
  
  constructor(
    private router:Router,private VilleService :VilleService
  ) { }

  ngOnInit(): void {
    // this.VilleService.fetchalllvilles().subscribe(res=>{})
  }
  // function pour redireger au acceuil.
  backtohome(){
    this.router.navigateByUrl('/historique');

  }

}
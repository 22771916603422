<div class="container">
    <img *ngIf="hidetype" routerLink="/historique" class="logop" src="../../../assets/logo-n.png">
    <img *ngIf="!hidetype" routerLink="/commander" class="logop" src="../../../assets/logo-n.png">
   
    <!-- <ul class="bar" style="width: max-content;"> -->
      <div class="bar">
        <hr *ngIf="!hidetype">
        <button *ngIf="!hidetype" class="color line " [class.active]="isRouteActive('/commander')" (click)="isClicked()" routerLink="/commander"><span > Payer mon assurance </span></button>
        <hr>
        <button class="color line " [class.active]="isRouteActive('/historique')" (click)="isClicked()" routerLink="/historique"><span > Mon Historique</span></button>
          <hr>
        <button class="color line " [class.active]="isRouteActive('/Informations-personnelles')" (click)="notClicked()" routerLink="/Informations-personnelles"><span> Mes informations personnelles</span> </button>
        <hr>
      </div>
       
      <!-- </ul> -->
      
    <div class="button-container">
      <i routerLink="" (onclick)="deconnexion()" title="deconnexion" class="fa-solid fa-arrow-right-from-bracket menu-button"></i>
            
        </div>
      
        <div class="footer">
            <h3 style="font-weight: 500;">Vous pouvez nous contacter sur l'email suivant :</h3>
            
            <p>
             <a href="mailto:reclamation@prevas.ma">reclamation@prevas.ma</a>
            </p>
            <p>
              Tél : <a >05 22 020 903</a> / <a >05 22 020 908</a>
            </p>
          </div>
        
</div>
  <div class="bg-modal">
      <div class="modal-content">
        
        <div class="title">
          <div style="position: absolute;
        right: 2%;
        font-size: 18px;
        color: white;
        top: 14px;" (click)="closePopUp()">
          <i  class="fas fa-times"></i>
        </div>
         <h3>Attention!</h3>
        </div>
        <div style="padding: 16px;">
         <h4>Veuillez noter que les informations ci-dessous sont utilisées pour valider votre inscription auprès de votre établissement universitaire et ne pourront plus être modifiées après votre commande:</h4>
     <div class="content">
      <label [(ngModel)]="client" name="cin" for="cin" ngDefaultControl><small style="color: #221717;">CIN (Numéro de la Carte d'identité Nationale) :</small>  {{client.cin}}</label>
                            
      <label [(ngModel)]="client" for="cne" name="cne" ngDefaultControl><small style="color: #221717;">CNE (Code National de l'Etudiant - code Massar) :</small>  {{client.cne}}</label>
                         
    <label [(ngModel)]="client" for="nom" name="nom" ngDefaultControl><small style="color: #221717;">Nom :</small> {{client.nom}}</label>
                         
     <label [(ngModel)]="client" for="prenom" name="prenom" ngDefaultControl><small style="color: #221717;">Prenom :</small>  {{client.prenom}}</label>
                         
     </div>
       <div class="checkbox-container">
        <input  [(ngModel)]="checkboxChecked" class="check" type="checkbox" id="confirmation" required >
     <label style=" cursor: pointer;font-weight: 500;font-size: 14px;" for="confirmation" class="confirm">Confirmation des informations</label>
     </div>
 <div class="btn">
   <button type="button" (click)="valider()" [disabled]="!checkboxChecked" [style.background-color]="buttonColor()">Valider</button>
   <button type="button" routerLink="/Informations-personnelles" (click)="closePopUp()">Modifier</button>

 </div> 
</div>           
   </div>
  </div>
  
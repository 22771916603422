
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './component/header/header.component';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { HistoriqueComponent } from './component/historique/historique.component';
 import { PopupComponent } from './component/popup/popup.component';
import {MatDialogModule} from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { PasswordComponent } from './component/password/password.component';
import { DesactiverCompteComponent } from './component/desactiver-compte/desactiver-compte.component';
import { ContactComponent } from './component/contact/contact.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { ResetpassComponent } from './resetpass/resetpass.component';
import { InformationsPersonnellesComponent } from './component/informations-personnelles/informations-personnelles.component';
import { AccountverifiedComponent } from './component/accountverified/accountverified.component';
import { TokenErrorComponent } from './component/accountverified/token-error/token-error.component';
import { TypeComponent } from './component/type/type.component';
import { NotFoundComponent } from './component/not-found/not-found.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { httpInterceptor } from 'src/interceptor/httpInterceptor';
import { ErrorInterceptor } from 'src/interceptor/errorInterceptor';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { NgOtpInputModule } from 'ng-otp-input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import { FaqComponent } from './component/faq/faq.component';
import { CgvComponent } from './component/cgv/cgv.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AllowedLettersDirective } from './services/allowed-letters.directive';
import { CmiErrorComponent } from './component/cmi-error/cmi-error.component';
import { CmiSuccessComponent } from './component/cmi-success/cmi-success.component';
 


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    SidebarComponent,
    TypeComponent,
    HistoriqueComponent,
    InformationsPersonnellesComponent,
    PopupComponent,
    PasswordComponent,
    DesactiverCompteComponent,
    ContactComponent,
    ResetpassComponent,
    AccountverifiedComponent,
    TokenErrorComponent,
    NotFoundComponent,
    FaqComponent,
    CgvComponent,
    AllowedLettersDirective,
    CmiErrorComponent,
    CmiSuccessComponent,

   
    
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxCaptchaModule,
    // ToastrModule.forRoot({
    //   timeOut: 3000,
    //   positionClass: 'toast-top-right',
    //   preventDuplicates: true,
    //   progressBar: true,
    // }),
    MatCheckboxModule ,
    MatSelectModule,
    ToastrModule.forRoot(),
    NgHttpLoaderModule.forRoot(),
    NgOtpInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,


  ],
  providers: [
    {provide:HTTP_INTERCEPTORS,useClass:httpInterceptor,multi:true},
    {provide:HTTP_INTERCEPTORS,useClass:ErrorInterceptor,multi:true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TypeComponent } from '../type/type.component';
import { ClientService } from 'src/app/services/client.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css'],
})
export class PopupComponent implements OnInit {
  client: any;
  checkboxChecked = false;

  constructor(
    private matdialog: MatDialog,
    private clientService: ClientService,
    private toastr: ToastrService
  ) {}

  // Méthode appelée lors de l'initialisation du composant
  ngOnInit(): void {
    this.atthebeginning();
  }

  // Méthode pour fermer toutes les boîtes de dialogue ouvertes
  closePopUp() {
    this.matdialog.closeAll();
  }

  // Méthode pour déterminer la couleur du bouton en fonction de l'état de la case à cocher
  buttonColor(): string {
    return this.checkboxChecked ? '#29234A' : '#56536a';
  }

  // Méthode pour valider l'action
  valider() {
    if (this.checkboxChecked) {
      // Envoie un message si la case est cochée
      this.clientService.changeMessage("Hello from Second Component");
      this.matdialog.closeAll();
    } else {
      // Affiche un avertissement si la case n'est pas cochée
      this.toastr.warning("Veuillez confirmer les informations en cochant la case appropriée.");
    }
  }

  // Méthode pour récupérer les informations du client au début
  atthebeginning() {
    const client = localStorage.getItem("user");
    if (client) {
      this.client = JSON.parse(client);
    }

    this.clientService.getclientbyid(this.client.id).subscribe(
      response => {
        this.client = response;
      },
      error => {
        console.error('Erreur lors de la récupération de l\'établissement:', error);
        if (error && error.error && error.error.message) {
          console.error('Message d\'erreur du serveur:', error.error.message);
        }
      }
    );
  }
}

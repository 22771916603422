import { Component } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent {
  faqItems = [
    {
      question: '1. What is Lorem Ipsum?',
      answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry...',
      expanded: false
    },
    {
      question: '2. Best Lorem Ipsum?',
      answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry...',
      expanded: false
    },
    {
      question: '3. Why do we do Lorem Ipsum?',
      answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry...',
      expanded: false
    },
    {
      question: '4. What are Lorem Ipsum?',
      answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry...',
      expanded: false
    },
    {
      question: '5. What is Lorem Ipsum?',
      answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry...',
      expanded: false
    }
  ];

  toggleAnswer(item: any) {
    item.expanded = !item.expanded;
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-token-error',
  templateUrl: './token-error.component.html',
  styleUrls: ['./token-error.component.css']
})
export class TokenErrorComponent {

}

import { Component, ElementRef, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../popup/popup.component';
import { ClientService } from 'src/app/services/client.service';
import { VilleService } from 'src/app/services/ville.service';
import { EtablissementService } from 'src/app/services/etablissement.service';
import { FilliereService } from 'src/app/services/filliere.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CommandeService } from 'src/app/services/commande.service';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-type',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.css']
})
export class TypeComponent implements OnInit {
  @Output() messageEvent = new EventEmitter<string>();
payedlastyear:string= '';
  listville: any;
  selectedCity: any;
  listetablissement: any;
  selectedetablissement: any;
  listfilliere: any;
  selectedfilliere: any;
  client: any;
  ville: any;
  etab: any;
  filliere: any;
  niveauetude: any;
  currentYear: number = new Date().getFullYear();
  selectedYear: string = '';
  dateeffe: any;
  dateecheance: any;
  checkboxChecked1 = false;
  checkboxChecked2 = false;
  formNumber = 0;
  showValidationPopup= false;
  submitted=false;
  codeBinga:string;


  timeLeft: number =  2 * 60; 
  private subscription: Subscription = new Subscription();


  otp: string="";
otpstatus:number=0;

montant:number = 50;
addedone:number = 5.12;

total:number = null
// initial:number = 0
selectedPayment: string | null = null;


 
onPaymentOptionChange(paymentType: string, event: Event): void {
  const isChecked = (event.target as HTMLInputElement).checked;

  if (isChecked) {
    this.selectedPayment = paymentType;
    if (paymentType === 'card') {
      this.total = this.montant ;
      
     
    } else if(paymentType === 'cash') {
      this.total = this.montant + this.addedone;
      
    }
    
  } else {
    this.selectedPayment = null;
    this.total = null ;
  }

  // Ensure only one checkbox is checked at a time
  if (paymentType === 'card' && isChecked) {
    this.selectedPayment = 'card';
  } else if (paymentType === 'cash' && isChecked) {
    this.selectedPayment = 'cash';
  }
}
  constructor(
    private matdialog: MatDialog,
    private el: ElementRef,
    private clientService: ClientService,
    private renderer: Renderer2,
    private villeservice: VilleService,
    private etablissementservice: EtablissementService,
    private filliereservice: FilliereService,
    private toastr: ToastrService,
    private router: Router,
    private loginservice:ClientService,
    private commandeserv:CommandeService
  ) {
    // Initialisation des objets client, ville, etab et filliere
    this.client = { nom: '', prenom: '', villeEtab: '', etab: '', filliere: '', cin: '', cne: '' };
    this.ville = { nom: '', id: '', villeCode: '' };
    this.etab = { id: '', nom: '', villeid: '' };
    this.filliere = { id: '', name: '', etablissementId: '' };
  }

  // Vérifie si tous les champs du formulaire sont remplis
  AllInputsFilled(): boolean {
    return (
      this.selectedCity && this.selectedCity != "undefined" &&
      this.selectedetablissement && this.selectedetablissement != "undefined" &&
      this.selectedfilliere && this.selectedfilliere != "undefined" &&
      this.selectedYear && this.selectedYear != "undefined" &&
      this.niveauetude && this.niveauetude != "undefined"
    );
  }

  // Méthode appelée lors de l'initialisation du composant
  ngOnInit(): void {
this.payedlastyear = localStorage.getItem("payeeLastYear")

    const payee = localStorage.getItem("payee");
    if (payee == "true") {
      this.router.navigateByUrl('/notfound');
    } else {
      this.fetchCities();
      const client = localStorage.getItem("user");
      
      if (client) {
        this.client = JSON.parse(client);
        console.log(this.client);
      }
      this.clientService.currentMessage.subscribe(message => this.onNextClick2());
      this.onBackClick();
    }
    this.emailmasqued = this.masquerEmail(this.client?.email);


    
   
  }

  // Récupère la liste des villes
  fetchCities() {
    this.villeservice.fetchalllvilles().subscribe(
      (data: string[]) => {
        this.listville = data; // Assigne les villes récupérées à la variable listville
      },
      (error) => {
        console.error('Erreur lors de la récupération des villes:', error);
      }
    );
  }

  // Change l'année sélectionnée et met à jour les dates en conséquence


  // 02/09/2024

  // onchangeanneuni(year: any) {
  //   this.selectedYear = year;
  //   if (year === `${this.currentYear}-${this.currentYear + 1}`) {
  //     this.dateeffe = `01/09/${this.currentYear}`;
  //     this.dateecheance = `31/08/${this.currentYear + 1}`;
  //   } else if (year === `${this.currentYear - 1}-${this.currentYear}`) {
  //     this.dateeffe = `01/09/${this.currentYear - 1}`;
  //     this.dateecheance = `31/08/${this.currentYear}`;
  //   }
  // }
  
  onchangeanneuni(year: any) {
    this.selectedYear = year;
  
    const today = new Date();
    const nextYear = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate() - 1);
  
    const formattedToday = `${today.getDate().toString().padStart(2, '0')}/${(today.getMonth() + 1).toString().padStart(2, '0')}/${today.getFullYear()}`;
    const formattedNextYear = `${nextYear.getDate().toString().padStart(2, '0')}/${(nextYear.getMonth() + 1).toString().padStart(2, '0')}/${nextYear.getFullYear()}`;
  
   
      this.dateeffe = formattedToday;
      this.dateecheance = formattedNextYear;
    
  }
  // Change le niveau d'étude sélectionné
  onchangeniveau(niv: any) {
    this.niveauetude = niv;
  }

  // Change la ville sélectionnée et met à jour les établissements en conséquence
  onCityChange(city: any) {
    this.selectedetablissement = undefined;
    this.selectedfilliere = undefined;
    this.selectedCity = city;
    this.ville = city;
    this.listfilliere = [];
    this.listetablissement = [];
    this.etablissementservice.fetchetablissment(this.selectedCity.id).subscribe(
      response => {
        this.listetablissement = response;
      },
      error => {
        console.error('Erreur lors de la récupération des établissements:', error);
      }
    );
  }

  // Change la filière sélectionnée
  onchangefilliere(filliere: any) {
    this.selectedfilliere = filliere;
    this.filliere = filliere;
  }

  // Change l'établissement sélectionné et met à jour les filières en conséquence
  onetablissementchange(etab: any) {
    this.selectedfilliere = undefined;
    this.filliere = [];
    this.selectedetablissement = etab;
    this.etab = etab;
    this.filliereservice.fetchetfilliere(this.selectedetablissement.id).subscribe(
      response => {
        this.listfilliere = response;
      },
      error => {
        console.error('Erreur lors de la récupération des filières:', error);
      }
    );
  }

  // Envoie un message à un autre composant
  sendMessage() {
    this.messageEvent.emit("test");
  }

  // Ouvre une popup si la case est cochée
  openPopUp() {
    if (this.checkboxChecked1) {
      this.matdialog.open(PopupComponent);
    } else {
      this.toastr.warning("Veuillez certifier l'exactitude des informations en cochant la case.");
    }
  }

  // Ferme toutes les popups ouvertes
  closePopUp() {
    this.matdialog.closeAll();
  }

  // Recharge la page pour revenir en arrière
  goback() {
    location.reload();
  }

  // Change la couleur du bouton en fonction de la case cochée
  buttonColor1(): string {
    return this.checkboxChecked1 ? '#29234A' : '#56536a';
  }

  buttonColor2(): string {
    return this.checkboxChecked2 ? '#29234A' : '#56536a';
  }

  // Passe au formulaire suivant si tous les champs sont remplis
  onNextClick(): void {
    if (this.AllInputsFilled()) {
      this.formNumber++;
      this.updateForm();
      this.contentChange();
    } else {
      this.toastr.warning("Veuillez remplir tous les champs");
    }
  }

  // Passe au formulaire suivant
  private onNextClick2(): void {
    const mainForm = this.el.nativeElement.querySelectorAll('.main');
    if (this.formNumber < mainForm.length - 1) {
      this.formNumber++;
      this.updateForm();
      this.contentChange();
    }
  }
//otp
 masquerEmail(email) {
  // Sépare l'adresse e-mail en deux parties : avant et après le '@'
  const [localPart, domain] = email.split('@');
  
  // Prend les deux premières lettres de la partie locale
  const visiblePart = localPart.slice(0, 2);
  
  // Concatène les deux premières lettres avec le domaine
  const maskedEmail = `${visiblePart}***@${domain}`;
  
  return maskedEmail;
}
otpCode: string = '';
emailmasqued:string="";
handleFillEvent(value: string): void {
  console.log(value+"sdsd");
}
 verifyOtp() {
  // this.submitted= !this.submitted;
   this.loginservice.verifyOtp(this.client.email, this.otp).subscribe((response:any) => {
if(response?.message=="OTP vérifié avec succès."){
  this.otpstatus=3;

}
this.closeValidationPopup();
if(this.selectedPayment=="card")
     this.onNextClickpayment();
    else if(this.selectedPayment=="cash")
      {
        this.onNextcashpayment();
      }
     
     console.log('OTP verified');
    },
    error => {
      if(error?.error){
        if(error?.error?.message )
          {
              if(error?.error?.message=="Code OTP invalide.")
                this.otpstatus=1;
              else if(error?.error?.message=="Le code OTP a expiré")
                this.otpstatus=2;
             

          }
      }
      console.error('Erreur :', error?.message);
    })
 }
//on time password two fa
  Verify2fa(){
    if(this.selectedPayment != null && this.selectedPayment != ""){

      this.showValidationPopup=true;
      this.loginservice.sendOtp(this.client.email).subscribe(response => {
  
        this.toastr.info("OTP envoyé à votre adresse email");
        const source = interval(1000); 
        this.subscription = source.subscribe(val => {
          if (this.timeLeft > 0) {
            this.timeLeft--;
          } else {
            this.subscription.unsubscribe();
          }
        });
        console.log('OTP sent to email');
      },
      error => {
        if(error?.error){
          if(error?.error?.message =="Vous ne pouvez pas demander un nouveau code avant qu'une minute ne se soit écoulée." )
            {
              this.otpstatus=4;
            }
          }
        console.error(error);
        } );
        console.log(this.selectedCity)
        const commande = {
            id: this.client.id,
            Nom: this.client.nom,
            Prenom: this.client.prenom,
            Email: this.client.email,
            Cne: this.client.cne,
            Cin: this.client.cin,
            NiveauEtude: this.niveauetude,
            Etablisement: this.selectedetablissement.nom,
            Filliere: this.selectedfilliere.name,
            dateeffe: this.selectedYear,
            ville: this.selectedCity.id,
            Adresse: this.client.adresse,
            Telephone: this.client.telephone
  
        }
        // this.commandeserv.cardpayment(commande).subscribe({
        //     next: (value: any) => {
        //         console.log(value);
        //     },
        // })
    }else{
     this.toastr.warning('Veuillez choisir un mode de paiement');
    }

  }
  // Passe au formulaire de paiement si la case est cochée
  onNextClickpayment() {
    const commande ={
      id:this.client.id,
      Nom:this.client.nom,
      Prenom:this.client.prenom,
      Email:this.client.email,
      Cne:this.client.cne,
      Cin:this.client.cin,
      NiveauEtude:this.niveauetude,
      Etablisement:this.selectedetablissement.nom,
      Filliere:this.selectedfilliere.name,
      dateeffe:this.selectedYear,
      ville:this.selectedCity.id,
      Adresse:this.client.adresse,
      Telephone:this.client.telephone

    }
    
// this.commandeserv.Cashpayment(commande).subscribe({
//   next:(value:any)=> {
//     console.log(value)
//   },
// })
this.commandeserv.cardpayment(commande).subscribe({
  next: (value: any) => {
      console.log(value);
      const paymentFormContainer = document.createElement('div');
      paymentFormContainer.innerHTML = value;
      document.body.appendChild(paymentFormContainer);
      const paymentForm = paymentFormContainer.querySelector('form');
      if (paymentForm) {
        paymentForm.submit();
      }
  },
})

    // if (this.checkboxChecked2) {
    //   this.formNumber++;
    //   this.updateForm();
    //   this.contentChange();
    // } else {
    //   this.toastr.warning("Veuillez accepter les conditions générales de vente pour continuer.");
    // }
  }
  onNextcashpayment() {
    const commande ={
      id:this.client.id,
      Nom:this.client.nom,
      Prenom:this.client.prenom,
      Email:this.client.email,
      Cne:this.client.cne,
      Cin:this.client.cin,
      NiveauEtude:this.niveauetude,
      Etablisement:this.selectedetablissement.nom,
      Filliere:this.selectedfilliere.name,
      dateeffe:this.selectedYear,
      ville:this.selectedCity.id,
      Adresse:this.client.adresse,
      Telephone:this.client.telephone

    }
    
this.commandeserv.Cashpayment(commande).subscribe({
  next:(value:any)=> {
    this.codeBinga = value;
    this.formNumber++;
      this.updateForm();
      this.contentChange();
  },
})

   
  }
  // Reculer au formulaire précédent
  onBackClick(): void {
    this.formNumber--;
    this.updateForm();
    this.contentChange();
  }

  // Met à jour la classe CSS du formulaire en cours
  private updateForm(): void {
    const mainForm = this.el.nativeElement.querySelectorAll('.main');
    mainForm.forEach((mainFormNumber: HTMLElement) => {
      this.renderer.removeClass(mainFormNumber, 'active');
    });
    this.renderer.addClass(mainForm[this.formNumber], 'active');
  }

  // Change le contenu en fonction du formulaire en cours
  private contentChange(): void {
    const stepNumContent = this.el.nativeElement.querySelectorAll('.step-number-content');
    if (this.formNumber < stepNumContent.length) {
      stepNumContent.forEach((content: HTMLElement) => {
        this.renderer.removeClass(content, 'active');
        this.renderer.addClass(content, 'd-none');
      });
      this.renderer.addClass(stepNumContent[this.formNumber], 'active');
    }
  }

  onOtpChange(otp) {
    this.otp = otp;
  }
  closeValidationPopup() {
    this.showValidationPopup = false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe(); 
  }

  get formattedTime(): string {
    const minutes: number = Math.floor(this.timeLeft / 60);
    const seconds: number = this.timeLeft % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }
}

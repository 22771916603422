<app-sidebar></app-sidebar>
<div class="all">
    <p routerLink="/commander" class="back_title"><i style="color: #29234A;font-size: 15px;" class="fas fa-arrow-left"></i>
        <a class="goback">Revenir à l'accueil</a></p>
    <div class="image">
    <img src="./../../../assets/logo_cmi.png">
    </div>
    <div class="">
        <div class="nmbr">
            N de la commande
            <strong> $request->oid </strong>
        </div>
        <p class="approuved">Nous confirmons que votre commande a été passée avec succès et nous vous informons que vous recevez votre attestation dans
             les prochines 24 heure, sur l'email <a>email</a>.
    
        </p>
        <div class="price">
            Montant payée : <strong> $request->amount  DH</strong>
        </div>
        <h3>Récapitulatif</h3>
     
        
        <table >
           
            <tr>
                <td><strong>Nom Prénom bénéficiaire&nbsp;</strong></td>
                <td>$request->BillToName</td>
            </tr>
            <tr>
                <td><strong>N de la piece d'identité&nbsp;</strong></td>
                <td>$cin </td>
            </tr>
            <tr>
                <td><strong>Status de paiement&nbsp;&nbsp;</strong></td>
                <td>Payée</td>
            </tr>
        
            <tr>
                <td><strong>Date d'effet&nbsp;</strong></td>
                <td>request->EXTRA_TRXDATE</td>
            </tr>
            
        
        </table>
    </div>
    
    
</div>

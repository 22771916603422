<div class="container">
  <div class="logo">
    <img class="logop" src="../../../assets/logo-n.png">
  </div>
  
  <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
    <div class="form-group">
      <label for="newPassword">Nouveau mot de passe</label>
      <input type="password" id="newPassword" formControlName="newPassword" required maxlength="13">
      <div class="error-message" *ngIf="resetPasswordForm.get('newPassword')?.hasError('pattern') && resetPasswordForm.get('newPassword')?.dirty">
        Le mot de passe doit contenir au moins 6 caractères, 1 lettre et 1 chiffre.
      </div>
      <div class="error-message" *ngIf="resetPasswordForm.get('newPassword')?.hasError('maxlength') ">
        Le mot de passe ne peut pas dépasser 12 caractères.
      </div>
    </div>
  
    <div class="form-group">
      <label for="confirmPassword">Confirmer mot de passe</label>
      <input type="password" id="confirmPassword" formControlName="confirmPassword" required >
      <div class="error-message" *ngIf="resetPasswordForm.hasError('passwordMismatch') && resetPasswordForm.get('confirmPassword')?.dirty">
        Le champ de confirmation du mot de passe ne correspond pas au mot de passe.
      </div>
    </div>
  
    <button type="submit" [disabled]="resetPasswordForm.invalid">Enregistrer</button>
  </form>
</div>

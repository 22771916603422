import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './component/header/header.component';
import { TypeComponent } from './component/type/type.component';
import { HistoriqueComponent } from './component/historique/historique.component';
import { InformationsPersonnellesComponent } from './component/informations-personnelles/informations-personnelles.component';
import { PopupComponent } from './component/popup/popup.component';
import { PasswordComponent } from './component/password/password.component';
import { ContactComponent } from './component/contact/contact.component';
import { DesactiverCompteComponent } from './component/desactiver-compte/desactiver-compte.component';
import { ResetpassComponent } from './resetpass/resetpass.component';
import { AccountverifiedComponent } from './component/accountverified/accountverified.component';
import { TokenErrorComponent } from './component/accountverified/token-error/token-error.component';
import { NotFoundComponent } from './component/not-found/not-found.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FaqComponent } from './component/faq/faq.component';
import { CgvComponent } from './component/cgv/cgv.component';
import { CmiErrorComponent } from './component/cmi-error/cmi-error.component';
import { CmiSuccessComponent } from './component/cmi-success/cmi-success.component';

const routes: Routes = [
  {path: 'login' ,component:LoginComponent},
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {path: 'header' ,component:HeaderComponent},
  {path: 'commander', component:TypeComponent},
  {path: 'historique', component:HistoriqueComponent},
  {path: 'Informations-personnelles', component:InformationsPersonnellesComponent},
  {path: 'password', component:PasswordComponent},
  {path: 'contact', component:ContactComponent},
  {path: 'desactiver-compte',component:DesactiverCompteComponent},
  {path: 'notfound',component:NotFoundComponent},
  {path: 'faq', component:FaqComponent},
  {path: 'cmi_error', component:CmiErrorComponent},
  {path:'cmi_success', component:CmiSuccessComponent},

  {path: 'condition-generales-ventes' ,component:CgvComponent},

  
  // {path: 'resetpassword',component:ResetpassComponent},
  // R8829562
  // R7741841
  // CS546440
  {
    path: 'resetpassword/:token',
    component: ResetpassComponent,
  },
  {
    path: 'accountVerification/:token',
    component: AccountverifiedComponent,
  },
  {
    path: 'TokenExpired/:token',
    component: TokenErrorComponent,
  }
  // {path: 'pop' ,component:PopupComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  // providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}]
})
export class AppRoutingModule { }

<div class="all">
    <div class="overlay" [class.actives]="showValidationPopup"></div>

<div>
    <app-sidebar></app-sidebar>
</div>

<div class="container">
    
    <div class="card">
        
        <div class="form">
          
            <div >
                <div class="main active">
                    <div class="progress">
                        <div class="containe">	
                            <div class="wrapper">	
                            <div class="arrow-steps clearfix">
                                      <div class="step current"> <span> Données Devis</span> </div>
                                      <div class="step"> <span>Recapitulatif</span> </div>
                                      <div class="step size"> <span> Paiement</span> </div>
                                  </div>
                               
                            </div>
                            </div>
                    </div>
                    <div class="text">
                        <h2>Demande de nouveau devis en ligne</h2>
                        

                    </div>
                  
                   
                    <div class="input-text">
                        <div class="input-div">
                          <select required id="city" [(ngModel)]="selectedCity" (change)="onCityChange(selectedCity)"> 
                            <option [value]="undefined" selected disabled>Veuillez sélectionner la ville de votre établissement d'enseignement supérieur *</option>
                            <option *ngFor="let city of listville" [ngValue]="city">{{ city.nom }}</option>
                          </select>
                        </div>
                      </div>
                      

                    <div *ngIf="selectedCity" class="input-text" id="casacity" >
                        <div class="input-div">
                            <select id="casablanca" [(ngModel)]="selectedetablissement" (change)="onetablissementchange(selectedetablissement)"  required>
                                <option [value]="undefined" disabled selected>Veuillez choisir l'établissement d'enseignement supérieur *</option>
                                <option *ngFor="let etab of listetablissement" [ngValue]="etab">{{ etab.nom }}</option>
                            </select>
                        </div>
                    </div>
                    <div *ngIf="!selectedCity" class="input-text" id="casacity" >
                        <div class="input-div">
                            <select id="casablanca" disabled required>
                                <option [value]="undefined" disabled selected>Veuillez choisir l'établissement d'enseignement supérieur *</option>
                               
                            </select>
                        </div>
                    </div>
                    
                    
                    

                    

                    <div class="input-text">
                        <div class="input-div">
                            <select [(ngModel)]="selectedfilliere" (change)="onchangefilliere(selectedfilliere)" required>
                                <option [value]="undefined" disabled selected>Filière*</option>
                                <option   [ngValue]="filliere"  *ngFor="let filliere of listfilliere" >{{ filliere.name }}</option>
                            </select>
                        
                        </div>
                        
                          <div class="input-div">
                            <select (change)="onchangeanneuni(selectedYear)" [(ngModel)]="selectedYear" required>
                              <option value="" selected disabled>Année universitaire*</option>
                              <option [disabled]="payedlastyear ==='true' " [value]="(currentYear - 1) + '-' + currentYear">{{ currentYear - 1 }}-{{ currentYear }}</option>
                              <option [value]="currentYear + '-' + (currentYear + 1)">{{ currentYear }}-{{ currentYear + 1 }}</option>
                            </select>
                          </div>
                    </div>

                    <div class="input-text">
                        <div  class="input-div">
                            
                            <select [(ngModel)]="niveauetude"   (change)="onchangeniveau(niveauetude)" required>
                                <option [value]="undefined" disabled selected>Niveau d'études *</option>
                                <option>S1</option>
                                <option>S2</option>
                                <option>S3</option>
                                <option>S4</option>
                                <option>S5</option>
                                <option>S6</option>
                                <option>S7</option>
                                <option>S8</option>
                                <option>S9</option>
                                <option>S10</option>
                            </select>
                        </div>
                    </div>

                    <div class="buttons">
                        <button class="next_button" (click)="onNextClick()" 
                         [style.background-color]="AllInputsFilled() ? '#29234A':'#56536a' ">Continuer</button>
                    </div>
                </div>
                <div class="main">
                    <div class="progress">
                        <div class="containe">	
                            <div class="wrapper">
                            <div class="arrow-steps clearfix">
                                      <div class="step current"> <span> Données Devis</span> </div>
                                      <div class="step current" > <span>Recapitulatif</span> </div>
                                      <div class="step size"> <span> Paiement</span> </div>
                            </div>
                               
                            </div>
                            </div>
                    </div>
                    <div class="text">
                        <h2>Récapitulatif </h2>
                      
                    </div>
                   

            
                    
                        <table >
                            <tr>
                                <td><strong>CIN&nbsp;</strong></td>
                                <td>{{client.cin}}</td>
                            </tr>
                            <tr>
                                <td><strong>CNE&nbsp;</strong></td>
                                <td>{{client.cne}}</td>
                            </tr>
                            <tr>
                                <td><strong>Nom bénéficiaire&nbsp;</strong></td>
                                <td>{{client.nom}} </td>
                            </tr>
                            <tr>
                                <td><strong>Prénom bénéficiaire&nbsp;&nbsp;</strong></td>
                                <td>{{client.prenom}}</td>
                            </tr>
                            
                            <tr>
                                <td><strong>Etablissement&nbsp;</strong></td>
                                <td>{{etab.nom}}</td>
                            </tr>
                            <tr>
                                <td><strong>Filière&nbsp;</strong></td>
                                <td>{{filliere.name}}</td>
                            </tr>
                            <tr>
                                <td><strong>Niveau d'étude&nbsp;</strong></td>
                                <td>{{niveauetude}}</td>
                            </tr>
                            <tr>
                                <td><strong>Date d'effet&nbsp;</strong></td>
                                <td >{{dateeffe}}</td>
                            </tr>
                            <tr>
                                <td><strong>Date d'échéance&nbsp;</strong></td>
                                <td>{{dateecheance}}</td>
                            </tr>
                        </table>
                    
                   
                    
                        <!-- <p class="par">Voici le meilleur tarif correspondant aux critères que vous avez sélectionnés <span>50 DH</span></p>
                        <div class="price">
                            <p>Toutes taxes et frais de paiement compris.</p>
                        </div> -->
                        <div class="checkbox-container">
                            <input [(ngModel)]="checkboxChecked1" type="checkbox" id="confirmationCheckbox" required>
                            <label for="confirmationCheckbox" id="verification">
                                Je confirme avoir vérifié toutes les informations saisies
                                et je certifie l'exactitude de ces informations.
                            </label>
                        </div>
                    <div class="buttons button_space">
                        <button class="back_button butt" (click)="onBackClick()">Précédent</button>
                        <button class=" butt" (click)="openPopUp()" [disabled]="!checkboxChecked1" [style.background-color]="buttonColor1()">Continuer</button>
                   
                </div>
                </div>
                <div class="main">
                    <div class="progress">
                        <div class="containe">	
                            <div class="wrapper">	
                            <div class="arrow-steps clearfix">
                                      <div class="step current"> <span> Données Devis</span> </div>
                                      <div class="step current"> <span>Recapitulatif</span> </div>
                                      <div class="step current size"> <span> Paiement</span> </div>
                                  </div>
                               
                            </div>
                            </div>
                    </div>
                    <div class="text">
                        <h2>Paiement </h2>
                        <!-- <p class="accepting"><span>{{client.nom}}</span> ,vous devez accepter les conditions générales de vente de PREVAS Online pour continuer ci-dessous.</p> -->
                        <div id="paymentInfo">
                            <p class="para">Vous pouvez maintenant payer votre devis d'Assurance RC Etudiant.</p>
                            
                           
                            <p class="paragraph">Une fois votre paiement validé, vous pouvez télécharger votre attestation depuis votre messagerie ou en cliquant sur le menu « Mon Historique ». </p>
                            <div class="checkboxes" style="color: black;margin: 12px 0;">
                              <p style="margin: 0;font-weight: 600;">Merci de choisir votre option de paiement :</p>
                              <div class="payment">
                                <input id="card" type="checkbox" (change)="onPaymentOptionChange('card', $event)" [checked]="selectedPayment === 'card'"> 
                                <label for="card" style="margin: 0 0 0 10px; font-weight: 600; cursor: pointer;">paiement par carte bancaire</label>
                              </div>
                              <div class="payment" style="padding-top: 1px;">
                                <input id="cash" type="checkbox" (change)="onPaymentOptionChange('cash', $event)" [checked]="selectedPayment === 'cash'">
                                <label for="cash" style="margin: 0 0 0 10px; font-weight: 600; cursor: pointer;">paiement en espèce auprès d'une agence Wafacash</label>
                              </div>
                              <p class="montant">
                                <strong class="desprix" id="total">Montant total à payer:&nbsp;</strong>
                                <span *ngIf="total" class="prix">{{ total }} DH</span> 
                              </p>
                            </div>
                          </div>
                          
                    </div>
                   

                    <div id="acceptCheckbox">
                        <input [(ngModel)]="checkboxChecked2" type="checkbox" id="acceptConditions" class="check" required>
                        <label for="acceptConditions" style="font-weight: 600; font-size: 12px; cursor: pointer;">J'accepte les conditions générales de vente de PREVAS Online et je souhaite souscrire à cette assurance.
                             <!-- <a href="http://attestation.sharedsystem.com/condition-generales-ventes" style="color: #27ae60; text-decoration: underline;" target="_blank">Conditions générales de vente *</a> </label> -->
                             <a href="./../../../assets/Conditions_general.pdf" style="color: #27ae60; text-decoration: underline;" target="_blank">Conditions générales de vente *</a> </label>

                            </div>
                        
                    <div style="justify-content: space-between;">
                     <div class="buttons button1 button_space">
                        <button id="precedent" (click)="onBackClick()">Précédent</button>
    
                        <!-- <button class="next_button"  [disabled]="!checkboxChecked2" [style.background-color]="buttonColor2()" id="payInCash" (click)="onNextClickpayment()">Payer en espèce</button> -->
                        <button class="next_button"  [disabled]="!checkboxChecked2 || !this.selectedPayment" [style.background-color]="buttonColor2()" id="payByCard" (click)="Verify2fa()">Proceder au paiement</button>

                    </div>
                    <div class="">
                        <img style="width: 8%;
                        margin: 0 10px;" src="./../../../assets/logo_cmi.png" alt="Image 1">
                        <img src="./../../../assets/3D-secure.png" alt="Image 2">
                        <img style="width: 16%;
                        margin: 0 10px;" src="./../../../assets/wafacash.jpg" alt="Image 1">
                      </div>
                    </div>
                </div>
                
                


<!-- otp -->
<div class="popup-container" *ngIf="showValidationPopup">
    <div class="titre">
        <div style="position: absolute;
        cursor: pointer;
        right: 2%;
        font-size: 14px;
        top: 17px;" (click)="closeValidationPopup()">
          <i  class="fas fa-times"></i>
        </div>
      <h2 style="margin: 0;">Authentification à deux facteurs</h2>
     
    </div>
    
  <div style="padding: 20px;">
    <p class="info">Nous avons envoyé un code pin de vérification à votre email </p>
        <p class="info" style="text-align: center;">{{emailmasqued}}</p>
    <p class="info"> Entrez le code de l'email dans le champ ci-dessous</p> 
    <p class="info" style="margin: 10px 0;">Vous pouvez demander un nouveau code dans {{ formattedTime }} min</p>
    <ng-otp-input    (onInputChange)="onOtpChange($event)" placeholder="Entrer le code" [config]="{length:6}"></ng-otp-input>
    <p *ngIf="otpstatus==1 " style=" color: #da0e0e;
    font-size: 12px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 1px;">Code Pin Invalide .</p>
     <p *ngIf="otpstatus==2 " style=" color: #da0e0e;
     font-size: 12px;
     font-weight: 500;
     margin-top: 10px;
     margin-bottom: 1px;">Code Pin expiré. Veuillez renvoyez le code.</p>
      <p *ngIf="otpstatus==3 " style=" color: green;
      font-size: 12px;
      font-weight: 500;
      margin-top: 10px;
      margin-bottom: 1px;">Succès</p>
       <p *ngIf="otpstatus==4 " style=" color: #da0e0e;
       font-size: 12px;
       font-weight: 500;
       margin-top: 10px;
       width: 85%;
       margin-bottom: 1px;">Vous ne pouvez pas demander un nouveau code avant qu'une minute ne se soit écoulée.</p>

    <!-- <input type="text" [(ngModel)]="otpCode" placeholder="Enter OTP" /> -->
    <button  [disabled]="otp.length!=6" class="button-otp" (click)="verifyOtp()">Vérifier</button>
    <button    class="button-otp" (click)="Verify2fa()">Renvoyer</button>

  </div>
</div>

<!-- end otp -->




                <div class="main">
                    <p>
                        <img class="wafa" src="./../../../assets/wafacash.jpg" alt="Wafacash Logo">
                    </p>
                    <p style="color: #000;">
                        Merci de vous rendre à l'agence Wafacash la plus proche de vous afin d'effectuer le paiement, avec le code suivant:
                        <strong>{{codeBinga}}</strong>.
                    </p>
                    <p style="color: #000;">Une copie de ce message vous a été envoyé par email.</p>
                    <p  (click)="goback()" class="back_title"><i style="color: #29234A;font-size: 15px;" class="fas fa-arrow-left"></i>
                        <a class="goback">Revenir à l'accueil</a></p>

                </div>
               
                 <div class="main">
                     <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                         <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                    </svg>
                     
                    <div class="text congrats">
                        <h2>Congratulations!</h2><br>
                        <p> <span class="shown_name"></span> your information have been submitted successfully for the future reference we will contact you soon.</p>
                    </div>
                </div>
                
            
              

            

            </div>
        </div>
    </div>
    <div class="responsive" style="background: transparent;padding: 0;">
        <p style="
             margin: 15px 20px;
             font-size: 14px;
             font-weight: 600;">Vous pouvez nous contacter sur l'email suivant: <a style="text-decoration: underline;color: blue;" href="mailto:reclamation@prevas.ma">reclamation@prevas.ma</a></p>
            
     </div>
</div>
</div>
